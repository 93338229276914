<template>
    <header class="header-profile flex justify-between px-3 sm:px-6 items-center relative">
        <div class="logo flex items-center ">
            <div @click="showMenu = !showMenu" class="block lg:hidden mr-4 burger-menu relative cursor-pointer"
                :class="{show : showMenu}">
                <span></span>
            </div>
            <router-link to="/" class="mr-3 ">
                <img class="lg:w-auto w-14" src="@/assets/images/logo-profile.png">
            </router-link>
            <LanguageApp></LanguageApp>
        </div>
        <div class="menu-profile absolute z-10 lg:hidden" :class="{ 'hidden': !showMenu }">

            <nav class="flex items-center list-none flex-col lg:flex-row">
                <ProfileNav></ProfileNav>
            </nav>
        </div>
        <div class="menu-right relative flex items-center" @mouseleave="dropNotification = false, dropUser = false">
            <!-- <div class="notification  cursor-pointer">
                <div @click="dropNotification = !dropNotification, dropUser = false" class="relative cursor-pointer">
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.5 16.2071V16.5H0.5V16.2071L2.35355 14.3536L2.5 14.2071V14V9C2.5 6.09437 4.02219 3.78092 6.61528 3.16653L7 3.07538V2.68V2C7 1.44614 7.44614 1 8 1C8.55386 1 9 1.44614 9 2V2.68V3.07506L9.38434 3.16644C11.9681 3.78076 13.5 6.10482 13.5 9V14V14.2071L13.6464 14.3536L15.5 16.2071ZM9.41352 18.5C9.20605 19.0806 8.64884 19.5 8 19.5C7.34433 19.5 6.79074 19.0813 6.58536 18.5H9.41352Z"
                            stroke="#01334C" />
                    </svg>
                    <span v-if="notification">{{ notification }}</span>
                </div>
                <div v-show="dropNotification" class="drop">
                    <ul>
                        <li>notification 1</li>
                        <li>notification 2</li>
                    </ul>
                </div>
            </div> -->
            <div class="user">
                <div class="flex items-center cursor-pointer" @click="dropUser = !dropUser, dropNotification = false">
                    <span class="uppercase avatar" v-if="authStore.user.email">{{ authStore.user.email.charAt(0) }}</span>
                    <img src="@/assets/icon/arrow-lang.svg" class="ml-1" alt="arrow">
                </div>
                <div v-show="dropUser" class="drop">
                    <div>
                        <h5 class="font-bold mb-1">{{ $t('header.connected') }}:</h5>
                        <p class="mb-4 font-medium">{{ authStore.user.email }}</p>
                        <nav class="list-none">
                            <li>
                                <router-link to="/profile" class="underline flex items-center my-3">
                                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.66634 9.99996V9.56663C1.66634 9.33996 1.77301 9.12663 1.93967 9.02663C3.06634 8.35329 4.35301 7.99996 5.66634 7.99996C5.68634 7.99996 5.69967 7.99996 5.71967 8.00663C5.78634 7.53996 5.91967 7.09329 6.11301 6.68663C5.96634 6.67329 5.81967 6.66663 5.66634 6.66663C4.05301 6.66663 2.54634 7.11329 1.25967 7.87996C0.673008 8.22663 0.333008 8.87996 0.333008 9.56663V11.3333H6.50634C6.22634 10.9333 6.00634 10.48 5.85967 9.99996H1.66634Z"
                                            fill="#01334C" />
                                        <path
                                            d="M5.66667 5.99996C7.14 5.99996 8.33333 4.80663 8.33333 3.33329C8.33333 1.85996 7.14 0.666626 5.66667 0.666626C4.19333 0.666626 3 1.85996 3 3.33329C3 4.80663 4.19333 5.99996 5.66667 5.99996ZM5.66667 1.99996C6.4 1.99996 7 2.59996 7 3.33329C7 4.06663 6.4 4.66663 5.66667 4.66663C4.93333 4.66663 4.33333 4.06663 4.33333 3.33329C4.33333 2.59996 4.93333 1.99996 5.66667 1.99996Z"
                                            fill="#01334C" />
                                        <path
                                            d="M12.8333 8.66658C12.8333 8.51992 12.8133 8.38658 12.7933 8.24659L13.5533 7.57325L12.8866 6.41992L11.9199 6.74659C11.7066 6.56659 11.4666 6.42659 11.1999 6.32659L10.9999 5.33325H9.66661L9.46661 6.32659C9.19995 6.42659 8.95995 6.56659 8.74661 6.74659L7.77995 6.41992L7.11328 7.57325L7.87328 8.24659C7.85328 8.38658 7.83328 8.51992 7.83328 8.66658C7.83328 8.81325 7.85328 8.94658 7.87328 9.08658L7.11328 9.75992L7.77995 10.9133L8.74661 10.5866C8.95995 10.7666 9.19995 10.9066 9.46661 11.0066L9.66661 11.9999H10.9999L11.1999 11.0066C11.4666 10.9066 11.7066 10.7666 11.9199 10.5866L12.8866 10.9133L13.5533 9.75992L12.7933 9.08658C12.8133 8.94658 12.8333 8.81325 12.8333 8.66658ZM10.3333 9.99992C9.59995 9.99992 8.99995 9.39992 8.99995 8.66658C8.99995 7.93325 9.59995 7.33325 10.3333 7.33325C11.0666 7.33325 11.6666 7.93325 11.6666 8.66658C11.6666 9.39992 11.0666 9.99992 10.3333 9.99992Z"
                                            fill="#01334C" />
                                    </svg>
                                    {{ $t('header.settings') }}
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/bills" class="underline flex items-center my-3">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.333 2.66663H2.66634C1.92634 2.66663 1.33967 3.25996 1.33967 3.99996L1.33301 12C1.33301 12.74 1.92634 13.3333 2.66634 13.3333H13.333C14.073 13.3333 14.6663 12.74 14.6663 12V3.99996C14.6663 3.25996 14.073 2.66663 13.333 2.66663ZM13.333 12H2.66634V7.99996H13.333V12ZM13.333 5.33329H2.66634V3.99996H13.333V5.33329Z"
                                            fill="#01334C" />
                                    </svg>
                                    {{ $t('header.payments') }}
                                </router-link>
                            </li>
                            <li @click.prevent="logout()">
                                <span class="underline flex items-center my-3 cursor-pointer">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.7704 5.52558C11.5241 5.29594 11.142 5.29593 10.8956 5.52558C10.6238 5.77892 10.6262 6.21211 10.8975 6.46598C11.3305 6.87105 11.0505 7.60325 10.4576 7.60325H5.95436C5.6112 7.60325 5.33302 7.88143 5.33302 8.22459C5.33302 8.56774 5.6112 8.84592 5.95436 8.84592H10.4584C11.05 8.84592 11.3307 9.57502 10.898 9.97834C10.6258 10.232 10.6231 10.6649 10.8944 10.9196C11.1413 11.1513 11.5254 11.1519 11.7731 10.9211L12.8855 9.8844C13.8481 8.98722 13.8481 7.46195 12.8855 6.56477L11.7704 5.52558ZM2.66635 6.54191C2.66635 5.06915 3.86026 3.87524 5.33302 3.87524H7.37836C7.72151 3.87524 7.99969 3.59706 7.99969 3.2539C7.99969 2.91075 7.72151 2.63257 7.37836 2.63257H2.66635C1.93301 2.63257 1.33301 3.19177 1.33301 3.87524V12.5739C1.33301 13.2574 1.93301 13.8166 2.66635 13.8166H7.37836C7.72151 13.8166 7.99969 13.5384 7.99969 13.1953C7.99969 12.8521 7.72151 12.5739 7.37836 12.5739H5.33302C3.86026 12.5739 2.66635 11.38 2.66635 9.90726V6.54191Z"
                                            fill="#01334C" />
                                    </svg>
                                    {{ $t('header.logout') }}
                                </span>
                            </li>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </header>
</template>

<script>
import LanguageApp from "@/components/ui/LanguageApp";
import ProfileNav from "@/components/ui/ProfileNav";
import { useAuthStore } from "@/store";
export default {
    name: "AdminMenu",
    components: {
        ProfileNav, LanguageApp
    },
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        }
    },
    methods: {
        logout() {
            this.authStore.logout();
        }
    },  
    data() {
        return {
            dropUser: false,
            showMenu: false,
            notification: 2,
            dropNotification: false,
            email: "loremipsumdolor@lorepi.com"
        }
    },
}
</script>

<style scoped>

</style>
