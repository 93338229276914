<template>
  <div class="container lg:mt-48 mt-32">

    <div class="mxm-15 flex items-start mb-16">
      <div class="md:w-1/3 w-full flex items-start">
        <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{$t('cfo.header')}}</h3>
        <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
      </div>
      <div class="md:w-2/3 w-full">
        <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">{{$t('cfo.headerInfo')}}</h1>
        <p class="text-base mb-8">{{$t('cfo.headerInfoText')}}</p>
        <img src="@/assets/icon/cfo.svg" alt="">
      </div>
    </div>

    <div class="mb-16">
      <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto mb-8">{{$t('cfo.managerHeader')}}</h3>
      <ul class="leading-[20px] pl-4 list-disc">
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.list.1')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.list.2')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.list.3')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.list.4')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.list.5')}}</p></li>
      </ul>
    </div>

    <div class="mb-16">
      <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto mb-8">{{$t('cfo.cfoHeader')}}</h3>
      <ul class="leading-[20px] pl-4 list-disc">
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.1')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.2')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.3')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.4')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.5')}}</p></li>
        <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('cfo.listSecond.6')}}</p></li>
      </ul>
    </div>
    <PayNow :serviceId="3" />
    <WhatClientsSays/>
    <GetInTouch/>

  </div>

  <ContactsHome/>

</template>

<script>
import ContactsHome from "@/components/home/ContactsHome.vue";
import GetInTouch from "@/components/GetInTouch.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from "@/components/WhatClientsSays.vue";

export default {
  name: "CfoPage",
  components: {
    GetInTouch, 
    ContactsHome,
    PayNow,
    WhatClientsSays,
  }
}
</script>

<style scoped>

</style>
