<template>
	<div class="flex mxm-15 justify-center flex-wrap">
		<div class="w-full lg:w-[745px] xl:w-[900px] lg:order-2 profile-box">
			<div class="flex mxm-15 flex-wrap">
				<div class="xl:w-2xl w-full xl:ml-auto">
					<AboutProfile></AboutProfile>
					<ProfilePerson></ProfilePerson>
					<ProfileAddress></ProfileAddress>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AboutProfile from "@/components/profile/AboutProfile";
import ProfilePerson from "@/components/profile/ProfilePerson";
import ProfileAddress from "@/components/profile/ProfileAddress";

export default {
	name: "ProfileView",
	components: {
		ProfileAddress,
		ProfilePerson,
		AboutProfile
	}
}
</script>
