<template>
  <div class="container lg:mt-48 mt-32">
    <div class="">
      <h1 class="mb-4">Terms</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem fugiat hic molestiae, nulla possimus quisquam
        repellat ullam voluptatibus. Aliquam cumque dolor eos error et magni maiores quisquam rerum veniam voluptate.</p>
      <p>Ab at cum earum perferendis ratione voluptatum. Amet architecto asperiores dolorum eligendi exercitationem id, in
        magni necessitatibus veritatis! Ab dolor doloribus et facilis ipsum natus neque numquam rerum velit vero?</p>
      <p>Consequatur dolore facilis necessitatibus nostrum odit, possimus quaerat quia, ratione reprehenderit suscipit
        totam voluptas. Assumenda beatae cumque eaque laborum libero modi nam neque perferendis provident unde? Nemo omnis
        recusandae repellat?</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsView"
}
</script>

<style scoped>

</style>
