import { defineStore } from 'pinia';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        items: (JSON.parse(localStorage.getItem('cart_items')) == null ? [] : JSON.parse(localStorage.getItem('cart_items'))),
    }),
    actions: {
        list() {
            return this.items;
        },
        isEmpty() {
            let item_count = this.items.filter(x => x.type === 'item').length;
            if(item_count === 0) {
                return true;
            } else {
                return false;
            }
        },
        add(item) {
            this.items.push(item);
            localStorage.setItem('cart_items', JSON.stringify(this.items));
        },
        removeAll() {
            this.items = [];
            localStorage.removeItem('cart_items');
        },
        removeById(id) {
            let index = this.items.findIndex(x => x.id == id);
            this.items.splice(index, 1);

            localStorage.setItem('cart_items', JSON.stringify(this.items));
        },
        removeByType(type) {
            let index = this.items.findIndex(x => x.type == type);

            this.items.splice(index, 1);
            localStorage.setItem('cart_items', JSON.stringify(this.items));
        },
        removeByIndex(index) {
            console.log(`index: ${index}`);
            this.items.splice(index, 1);
            localStorage.setItem('cart_items', JSON.stringify(this.items));
        },
        calculatePercentFromTotal(percent) {
            return ((percent * this.total()) / 100).toFixed(2);
        },
        getDiscountCode() {
            let index = this.items.findIndex(x => x.type === "discount");
            console.log(index);
            console.log(this.items);

            if(index != undefined) {
                if(this.items[index]) {
                    return this.items[index].discount_code;
                }
            }
        },
        haveDiscount() {
            let index = this.items.findIndex(x => x.type === "discount");
            if(index === -1) {
                console.log(`Dont have discount`);
                return true;
            } else {
                console.log(`Have discount ${index}`)
                return false;
            }
        },
        total() {
            let total = 0;
            if(this.items != null) {
                this.items.forEach((item) => {
                    if(item.type == 'item') {
                        total += parseFloat(item.price * item.quantity);
                    } else {
                        total -= parseFloat(item.price);
                    }
                });
            }

            return total;
        }
    }
});
