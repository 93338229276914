<template>
	<div class="px-2 xl:px-6">
		<form class="flex flex-wrap justify-between">
			<div class="flex flex-wrap items-center -mx-2.5">
				<div class="font-medium px-2.5 mb-2 w-full sm:w-auto">{{ $t('bills.filter') }}</div>
				<!-- <div class="px-2.5 mb-2">
					<select name="" id=""
						class="custom text-[13px] py-3 pl-4 pr-8 rounded bg-[#F3F6F6] border border-[#A1AEB7] w-40 cursor-pointer">
						<option>{{ $t('bills.allAccounts') }}</option>
						<option>2</option>
						<option>3</option>
					</select>
				</div>
				<div class="px-2.5 mb-2">
					<select name="" id=""
						class="custom text-[13px] py-3 pl-4 pr-8 rounded bg-[#F3F6F6] border border-[#A1AEB7] w-40 cursor-pointer">
						<option>{{ $t('bills.vat') }}</option>
						<option>2</option>
						<option>3</option>
					</select>
				</div> -->
				<div class="px-2.5 mb-2">
					<select name="" id="" @change="changeYear" v-model="currentFilter.year" class="custom text-[13px] py-3 pl-4 pr-8 rounded bg-[#F3F6F6] border border-[#A1AEB7] w-40 cursor-pointer">
						<option v-for="year in years" :key="year" :value="year">{{ year }} {{ $t('bills.year') }}</option>
					</select>
				</div>
			</div>
			<div class="pl-2.5 grow">
				<!-- <button class="btn-orange flex items-center mb-2 ml-auto" type="submit">
					<svg class="mr-3" width="10" height="12" viewBox="0 0 10 12" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M3.00065 8.66667H7.00065V4.66667H9.66732L5.00065 0L0.333984 4.66667H3.00065V8.66667ZM0.333984 10H9.66732V11.3333H0.333984V10Z"
							fill="white" />
					</svg>
					{{ $t('bills.uploadBills') }}</button> -->
			</div>
		</form>

		<Loading v-if="loading"/>
		<div v-else class="table-custom mt-2 mb-2">
			<table>
				<thead>
					<tr>
						<th></th>
						<th>{{ $t('bills.data') }}</th>
						<th>{{ $t('bills.number') }}</th>
						<th>{{ $t('bills.written') }}</th>
						<th>{{ $t('bills.type') }}</th>
						<th>{{ $t('common.status') }}</th>
						<th>{{ $t('bills.sum') }}</th>
						<th>{{ $t('common.invoice') }}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="order in orders" :key="order.id">
						<td>{{ order.id }}</td>
						<td>{{ order.created_at}}</td>
						<td>{{ order.document_number }}</td>
						<td>{{ order.business_name }}</td>
						<td>{{ $t('common.vat_invoice') }}</td>
						<td>{{ order.status }}</td>
						<td>€ {{ order.total }}</td>
						<td>
							<!-- <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.25 10.9375V13.75H3.0625L11.3575 5.45498L8.545 2.64248L0.25 10.9375ZM13.5325 3.27998C13.825 2.98748 13.825 2.51498 13.5325 2.22248L11.7775 0.467483C11.485 0.174983 11.0125 0.174983 10.72 0.467483L9.3475 1.83998L12.16 4.65248L13.5325 3.27998Z"
									fill="#6B8A97" />
							</svg> -->
							<div class="download-invoice" v-if="order.payed === 1" @click.prevent="getInvoice(order.id)">{{ $t('common.download_invoice')}}</div>
							<div v-else>-</div>
						</td>
					</tr>
	
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading.vue';
import Paginate from "vuejs-paginate-next";

export default {
	name: "BillsView",
	components: {
		Loading,
		Paginate,
	},
	data() {
		return {
			loading: false,
			orders: [],
			years: [],
			currentFilter: {
				year: null,
			}
		}
	},
	methods: {
		async changeYear(value) {
			await this.getOrders();
		},
		async getYears() {
			let vm = this;
			await axios.get('/orders/years').then((response) => {
				vm.years = response.data.years;
				vm.currentFilter.year = vm.years[0];
			}).catch((error) => {
				console.log(error);
			});
		},
		async getInvoice(id) {
			console.log(`Get invoice of ${id}`);
			await axios.get(`/orders/invoice/${id}`).then((response) => {
				console.log(response.data.data.url);

				location.replace(response.data.data.url);
			});
		},
		async getOrders(page) {
			let vm = this;
			vm.loading = true;
			await axios.get('/orders?page=' + (page == undefined ? 1 : page)  + '&year=' + this.currentFilter.year).then((response) => {
				vm.orders = response.data.data;
				vm.loading = false;
			})
		}
	},
	async created() {
		await this.getYears();
		this.getOrders();
	}
}
</script>

<style lang="scss" scoped>
	.download-invoice {
		cursor: pointer;
	}
</style>
