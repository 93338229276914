<template>
	<div class="flex flex-col items-center h-full auth-box">
		<LogoRegistration></LogoRegistration>
		<div class="login-block">
			<div class="white-box">
				<h4 class="font-semibold mb-6">{{ $t('auth.loginH') }}</h4>


				<Form class="mb-4" as="form" @submit="login">
					<TextField rules="required|email" name="email" :label="$t('form.email')" :placeholder="$t('form.email')" />
					<TextField rules="required" name="password" type="password" :label="$t('form.password')" :placeholder="$t('form.password')" />

					<button class="btn-yellow w-full">
						{{ $t('form.enter') }}
					</button>

				</Form>
			</div>

			<div class="flex flex-row-reverse mb-4 flex-wrap justify-between">
				<router-link to="/registration">{{ $t('auth.toRegistration') }}</router-link>
				<router-link to="/forgot-password">{{ $t('auth.forgot') }}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import { Form } from "vee-validate";
import LogoRegistration from "@/components/ui/LogoRegistration";
import { useToast } from "vue-toastification";
import axios from 'axios';
import { useAuthStore } from '@/store';
import { useRouter } from 'vue-router';

export default {
	name: "LoginPage",

	components: {
		TextField,
		LogoRegistration,
		Form,
	},

	data() {
		return {
			
		}
	},

	setup() {
		const toast = useToast();
		const authStore = useAuthStore();
		const router = useRouter();
		return {
			authStore,
			toast,
			router,
		}
	},

	methods: {
		async login(values, actions) {
			const { email, password } = values;
			let vm = this;

			axios.post('/login', {
				...values,
			}).then((response) => {
				console.log(response);
				if(response.data.status == 'success') {
					vm.authStore.setAuth(
						response.data.user, 
						response.data.access_token
					);

					vm.toast.success(response.data.message);

					if(vm.authStore.returnUrl) {
						vm.router.push(vm.authStore.returnUrl);
					} else {
						vm.router.push({
							name: 'profile'
						});
					}

				} else {
					vm.toast.error(response.data.message);
				}

				if (response) {
					if(response.data.errors) {
						for (const key of Object.keys(response.data.errors)) {
							actions.setFieldError(key, response.data.errors[key]);
						}
					} 
				}

			}).catch((error) => {
				console.log(error.response);
				if (error.response) {
					if(error.response.data) {
						if(error.response.data.status == 'error') {
							this.toast.error(error.response.data.message);
						}
					}


					if(error.response.data.errors) {
						for (const key of Object.keys(error.response.data.errors)) {
							actions.setFieldError(key, error.response.data.errors[key]);
						}
					} 
				}
			})
		},
	}
}
</script>

