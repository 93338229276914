<template>
	<div class="flex flex-col items-center auth-box">
		<LogoRegistration></LogoRegistration>
		<div class="login-block">
			<div class=" white-box">
				<h4 class=" mb-6">{{ $t('auth.registrationH') }}</h4>
				<Form class="mb-4" as="form" ref="registrationForm" @submit="register">

					<TextField rules="required|email" name="email" :label="$t('form.email')"
						:placeholder="$t('form.email')" />
					<TextField rules="required|email" name="email_confirmation" :label="$t('form.emailAgain')"
						:placeholder="$t('form.emailAgain')" />
					<TextField rules="required" name="password" type="password" :label="$t('form.password')"
						:placeholder="$t('form.password')" />
					<TextField rules="required:password" name="password_confirmation" :label="$t('form.password')"
						type="password" :placeholder="$t('form.password')" />
					<SelectField rules="required" name="type" :options="options" :label="$t('form.aYou')"
						:placeholder="$t('form.aYou')" />

					<button class="btn-yellow w-full">
						{{ $t('form.registration') }}
					</button>

				</Form>
			</div>
			<div class="flex flex-row-reverse mb-4 flex-wrap justify-between">
				<router-link to="/login">{{ $t('auth.toLogin') }}</router-link>
				<router-link to="/forgot-password">{{ $t('auth.forgot') }}</router-link>
			</div>

		</div>


	</div>
</template>
<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import { Form } from "vee-validate";
import LogoRegistration from "@/components/ui/LogoRegistration";
import { useToast } from "vue-toastification";
import axios from 'axios';
import { useAuthStore } from '@/store';
import { useRouter } from 'vue-router';

export default {
	name: "RegistrationPage",
	components: {
		LogoRegistration,
		Form,
		TextField,
		SelectField,
	},
	setup() {
		const toast = useToast();
		const authStore = useAuthStore();
		const router = useRouter();

		return {
			toast,
			authStore,
			router,
		}
	},
	data() {
		return {
			options: [
				{
					name: 'Organisation',
					value: 0,
				},
				{
					name: 'Invidual',
					value: 0,
				}
			]
		}
	},
	methods: {
		async register(values, actions) {
			await axios.post('/register', {
				...values,
			}).then((response) => {
				if (response.data.status === 'success') {
					this.toast.success(this.$t('auth.registerSuccessFully'));

					// set tokens and user 
					this.authStore.setAuth(
						response.data.user,
						response.data.access_token
					);

					// redirect to previous url or default to home page
					if (this.authStore.returnUrl) {
						router.push(this.authStore.returnUrl);
					} else {
						router.push({
							name: 'profile'
						});
					}

					actions.resetForm();
				}

				if (response) {
					if (response.data.errors) {
						for (const key of Object.keys(response.data.errors)) {
							actions.setFieldError(key, response.data.errors[key]);
						}
					}
				}
			}).catch((error) => {
				if (error.response) {
					if (error.response.data.errors) {
						for (const key of Object.keys(error.response.data.errors)) {
							actions.setFieldError(key, error.response.data.errors[key]);
						}
					}
				}
			})
		}
	}
}
</script>

<style scoped></style>
