import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import lt from '@/locales/lt.json'
import ru from '@/locales/ru.json'
import fr from '@/locales/fr.json'


export default createI18n({
  legacy: false,
  locale: 'lt',
  fallbackLocale: 'en',
  messages: {
    lt,
    en,
    ru,
    fr,
  },
});
