<template>
    <div class="flex justify-center mt-20 mb-16">
        <div class="lg:w-3/4 w-full bg-white rounded form-shadow py-6 px-6 md:py-10 md:px-20">
            <Form @submit="onSubmit" :validation-schema="schema">
                <h3 class="lg:text-2xl text-xl font-semibold mb-8">{{ $t('lending.contactH') }}</h3>
                <TextField rules="required" :label="$t('form.personName')" name="name" :placeholder="$t('form.personName')"/>
                <TextField rules="required|email" :label="$t('form.email')" name="email" :placeholder="$t('form.email')"/>
                <TextField rules="required" :label="$t('form.subjectThem')" name="subject" :placeholder="$t('form.subjectThem')"/>
                <TextField rules="required" :label="$t('form.yourInquiry')" name="message" :placeholder="$t('form.yourInquiry')"/>
                <div class="mb-14"><small>{{ $t('ui.required') }}</small></div>
                <button type="submit" class="btn-orange">{{ $t('ui.send') }}</button>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form } from "vee-validate";
import TextField from "@/components/inputs/TextField.vue";
import axios from 'axios';
import { useToast } from "vue-toastification";


export default {
    name: "GetInTouch",
    props: {
        title: {
            type: String,
            default: "Contact us",
        }
    },
    components: {               
        TextField,
        Form,
    },
    setup() {
        const toast = useToast();
      
        return {
            toast,
        }
    },
    data() {
        return {
           
        }
    },
    methods: {
        async onSubmit(values, actions) {
            let vm = this;
            await axios.post('/contact', {
                ...values
            }).then((response) => {
                vm.loading = false;

                if(response) {
                    if(response.data.status === 'success') {
                        vm.toast.success(response.data.message);
                        actions.resetForm();
                    }
                }
            }).catch((error) => {
                if(error.response.data.errors) {
                    vm.loading = false;

                    for (const key of Object.keys(error.response.data.errors)) {
                        console.log(error.response.data.errors[key])
                        actions.setFieldError(key, error.response.data.errors[key]);
                    }
                }
            });
        }
    }
}
</script>
<style scoped>

</style>
