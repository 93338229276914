<template>
  <header>
    <div class="homeMenu lg:p-6 p-4 flex fixed  top-0 left-0 z-40"
      :class="{ active: menuOpen, 'items-center': !menuOpen }">
      <div class="burger" @click="menuOpen = !menuOpen">
        <span></span>
        {{ !menuOpen ? $t('lending.menu') : $t('lending.menuClose') }}
      </div>
      <div class="languageHome">
        <span v-for="lang in languages" :key="lang" @click="switchLanguage(lang)" class="uppercase"
          :class="{ 'font-semibold': this.$i18n.locale === lang }">
          {{ lang }}
        </span>
      </div>
      <div class="nav modal-content">

        <div class="flex-grow-1 menuModal-content d-flex justify-content-center flex-column " v-show="menuOpen">
          <nav class="menuModal-nav list-none">
            <li><router-link to="/">{{ $t('menu.homePage') }}</router-link></li>
            <li><router-link to="/business-guidance"> {{ $t('menu.businessGuidance') }}</router-link></li>
            <li><router-link to="/business-audit">{{ $t('menu.businessAudit') }}</router-link></li>
            <li><router-link to="/accounting">{{ $t('menu.accounting') }}</router-link></li>
            <li><router-link to="/cfo">{{ $t('menu.cfo') }}</router-link></li>
            <li><router-link to="/ppwis">{{ $t('menu.ppwis') }}</router-link></li>
            <li><router-link to="/financial-audit">{{ $t('menu.financialAudit') }}</router-link></li>
            <li><router-link to="/project-management">{{ $t('menu.projectManagement') }}</router-link></li>
            <li><router-link to="/crisis-management">{{ $t('menu.crisisManagement') }}</router-link></li>
            <li><router-link to="/es-support">{{ $t('menu.es-supoort') }}</router-link></li>
          </nav>

          <router-link to="/login" class="btn-orange inline-flex items-center">
            <svg class="mr-2" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.28573 0H7.85715C7.46273 0 7.14287 0.319866 7.14287 0.714286C7.14287 1.10871 7.46273 1.42857 7.85715 1.42857H9.28573C9.68015 1.42857 10 1.74844 10 2.14286V7.85714C10 8.25156 9.68015 8.57143 9.28573 8.57143H7.85715C7.46273 8.57143 7.14287 8.89129 7.14287 9.28571C7.14287 9.68013 7.46273 10 7.85715 10H9.28573C10.4692 10 11.4286 9.04063 11.4286 7.85714V2.14286C11.4286 0.959375 10.4688 0 9.28573 0ZM7.64733 4.49554L4.79019 1.63839C4.51094 1.35915 4.05893 1.3596 3.78014 1.63839C3.50112 1.91741 3.50112 2.36942 3.78014 2.64844L5.41965 4.28571H0.714287C0.31942 4.28571 0 4.60491 0 5C0 5.39509 0.31942 5.71429 0.714287 5.71429H5.41965L3.7817 7.35223C3.50268 7.63125 3.50268 8.08326 3.7817 8.36228C4.06072 8.6413 4.51273 8.6413 4.79175 8.36228L7.6489 5.50513C7.92635 5.22545 7.92635 4.77455 7.64733 4.49554Z" />
            </svg>
            {{ $t('common.login') }}
          </router-link>
        </div>
      </div>

      <div
        class="homeLogo lg:flex hidden items-center lg:fixed top-4 right-4 z-40 ml-4 lg:ml-0 lg:w-28 lg:h-auto w-12 h-12">
        <router-link to="/">
          <img src="@/assets/images/logo-footer.png" alt="logo">
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { setLocale } from '@vee-validate/i18n';

export default {
  name: "HomeMenu",
  data() {
    return {
      menuOpen: false,
      languages: [
        'lt', 'en', 'ru', 'fr',
      ],
      routerName: this.$router.currentRoute
    }
  },
  methods: {
    switchLanguage(l) {
      if (this.$i18n.locale !== l) {
        this.$i18n.locale = l
        setLocale(l);
      }
    }
  },
  watch: {
    '$route'() {
      this.menuOpen = false;
    }
  }
}
</script>

<style scoped></style>
