<template>
    <div class="container lg:mt-48 mt-32">
        <div class="mxm-15 flex items-start mb-16">
            <div class="md:w-1/3 w-full flex items-start">
                <img src="@/assets/images/es.jpg" alt="">
            </div>
            <div class="md:w-2/3 w-full">
                <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('common.es_support') }}</h3>
                <p class="pt-10">
                    <b>{{ $t('essupport.0.content') }}</b>
                </p>
                <p class="pt-2"> {{ $t('essupport.1.content') }}</p>

                <p class="pt-2"> {{ $t('essupport.2.content') }}</p>

                <p class="pt-2"> {{ $t('essupport.3.content') }}</p>

                <p class="pt-2"><b>{{ $t('essupport.4.bold')}}</b> {{ $t('essupport.4.content') }}</p>

                <p class="pt-2"><b>{{ $t('essupport.5.bold')}}</b> {{ $t('essupport.5.content') }}</p>

                <p class="pt-2"><b>{{ $t('essupport.6.bold')}}</b>{{ $t('essupport.6.content') }}</p>

                <p class="pt-2"><b>{{ $t('essupport.7.bold')}}</b>{{ $t('essupport.7.content') }}</p>

                <p class="pt-2"><b>{{ $t('essupport.8.bold')}}</b>{{ $t('essupport.8.content') }}</p>
                <p class="pt-2"><b>{{ $t('essupport.9.bold')}}</b>{{ $t('essupport.9.content') }}</p>
                <p class="pt-2"><b>{{ $t('essupport.10.bold')}}</b>{{ $t('essupport.10.content') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped></style>