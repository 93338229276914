<template>
  <div class="homePage flex flex-col min-h-screen">
    <HomeMenu></HomeMenu>
    <div class="grow">
      <router-view/>
    </div>
    <FooterHome></FooterHome>
  </div>
</template>

<script>
import HomeMenu from "@/components/home/HomeMenu.vue";
import FooterHome from "@/components/home/FooterHome.vue";

export default {
  name: "LayoutMain",
  components: {FooterHome, HomeMenu}
}
</script>

<style scoped>

</style>
