<template>
	<div class="container lg:mt-48 mt-32">
		<div class="flex mxm-15 justify-center flex-wrap">
			<div class="w-full lg:w-[745px] xl:w-[900px] lg:order-2 profile-box">
				<div class="flex mxm-15 flex-wrap">
					<div class="xl:w-2xl w-full xl:ml-auto">
						<h3>{{ $t('common.payment_failed') }}</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useAuthStore } from '@/store'

export default {
	name: "PaymentSuccess",
	components: {

	},
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        }
    }
}
</script>
