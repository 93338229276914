<template>
  <div class="authentication">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "LayoutFull"
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/_authentication.scss";
</style>
