<template>
  <div class="languageApp relative px-2"
       @mouseleave="dropShow = false"
  >
    <button
        @click="dropShow = !dropShow"
        class="flex cursor-pointer uppercase items-center py-3 pr-3"
    >
      {{this.$i18n.locale}}
      <img src="@/assets/icon/arrow-lang.svg" class="ml-1" alt="arrow">
    </button>
    <div class="drop">
      <ul class=" uppercase" v-show="dropShow">

        <li
            v-for="lang in language"
            :key="lang"
            class="flex items-center cursor-pointer"
            @click="changeLocale(lang)"
        >
          {{lang}}
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { setLocale } from '@vee-validate/i18n';
export default {
  name: "LanguageApp",
  data() {
    return {
      language: [
        'lt','en', 'ru', 'fr',
      ],
      dropShow: false
    }
  },
  methods: {
    getImgUrl(pet) {
      const images = require.context('../../assets/icon/languages/', false, /\.svg$/)
      return images('./' + pet + ".svg")
    },
    changeLocale(l) {
      if (this.$i18n.locale !== l) {
        this.$i18n.locale = l
        this.dropShow = !this.dropShow
        setLocale(l);
      }
    }
  }
}
</script>

