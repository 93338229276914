<template>
    <div class="white-box mb-6 py-5 px-6">
        <h4>{{ $t('profile.about') }}
            <span class="ml-auto cursor-pointer opacity-70 hover:opacity-100" @click="editAbout = !editAbout">

                <svg  width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.25 10.9375V13.75H3.0625L11.3575 5.45501L8.545 2.64251L0.25 10.9375ZM13.5325 3.28001C13.825 2.98751 13.825 2.51501 13.5325 2.22251L11.7775 0.467513C11.485 0.175013 11.0125 0.175013 10.72 0.467513L9.3475 1.84001L12.16 4.65251L13.5325 3.28001Z"
                        fill="#6B8A97" />
                </svg>

            </span>
        </h4>

        <Form class="my-6 mxm-15 flex flex-wrap" ref="aboutForm" @submit="onsubmit" :initialValues="{ ...authStore.user }">

            <div class="w-full sm:w-1/2">
                <TextField rules="required" :label="$t('common.business_name')" name="business_name" :placeholder="$t('common.business_name')"/>
            </div>
            <div class="w-full sm:w-1/2">
                <TextField rules="required" :label="$t('common.business_registration_id')" name="business_registration_id" :placeholder="$t('common.business_registration_id')"/>
            </div>


            <div class="w-full text-right">
                <button class="btn-save" type="submit">{{ $t('form.save') }}</button>
            </div>

        </Form>
    </div>
</template>

<script>
import { Form } from "vee-validate";
import { useAuthStore } from "@/store";
import TextField from "@/components/inputs/TextField.vue";
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
    name: "AboutProfile",
    data() {
        return {
            loading: false,
        }
    },
    components: {
        Form,
        TextField,
    },
    setup() {
        const authStore = useAuthStore();
        const toast = useToast();
        return {
            authStore,
            toast,
        }
    },
    methods: {
        async onsubmit(values, actions) {
            let vm = this;
            vm.loading = true;
            await axios.post('/user/company/update', {
                ...values,
            }).then((response) => {
                vm.loading = false;
                if(response) {
                    if(response.data.status === 'success') {
                        vm.authStore.updateLocal(response.data.data);
                        vm.toast.success(response.data.message);
                    } else {
                        vm.toast.error(response.data.message);
                    }
                }
            }).catch((error) => {
                vm.loading = false;
                if (error.response) {
                    if(error.response.data.errors) {
                        for (const key of Object.keys(error.response.data.errors)) {
                            actions.setFieldError(key, error.response.data.errors[key]);
                        }
                    } 
                }
            });
        }
    }

}
</script>
