<template>
    <div class="container lg:mt-48 mt-32">

        <div class="mxm-15 flex items-start mb-20">
            <div class="md:w-1/2 w-full flex mb-4">
                <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('projectManagement.header') }}
                </h3>
                <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
            </div>
            <div class="md:w-1/2 w-full">
                <p class="text-base mb-4">{{ $t('projectManagement.headerText') }}</p>
                <p class="text-base mb-4">{{ $t('projectManagement.headerTextSecond') }}</p>
                <p class="text-base mb-4">{{ $t('projectManagement.headerTextThree') }}</p>
                <p class="text-base mb-4">{{ $t('projectManagement.headerTextFour') }}</p>
            </div>
        </div>

        <div class="mxm-15 flex items-start mb-20 justify-between">
            <div class="md:w-1/2 lg:w-2/5 w-full mb-8">
                <h3 class="lg:text-4xl text-2xl text-blue mb-4 font-semibold">{{ $t('projectManagement.businessIdea') }}
                </h3>
                <p class="mb-12">{{ $t('projectManagement.businessIdeaText') }}</p>
                <h3 class="lg:text-4xl text-2xl text-blue mb-4 font-semibold">{{ $t('projectManagement.expansion') }}
                </h3>
                <p class="mb-12">{{ $t('projectManagement.expansionText') }}</p>
                <h3 class="lg:text-4xl text-2xl text-blue mb-4 font-semibold">{{ $t('projectManagement.optimization') }}
                </h3>
                <p class="mb-12">{{ $t('projectManagement.optimizationText') }}</p>

            </div>
            <div class="md:w-1/2 w-full flex">
                <img src="@/assets/icon/projectManagement.svg" alt="project management">
            </div>
        </div>

        <div class="text-center">
            <h3 class="lg:text-4xl text-2xl text-blue mb-10 font-semibold">{{ $t('projectManagement.processes') }}</h3>
        </div>
        <div class="flex justify-center">
            <div class="xl:w-5/6">
                <div class="mxm-15 flex items-start mb-20 justify-between">

                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.initiative') }}
                        </div>
                    </div>
                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.planning') }}
                        </div>
                    </div>
                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.execution') }}
                        </div>
                    </div>
                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.monitoring') }}
                        </div>
                    </div>
                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.deadline') }}
                        </div>
                    </div>
                    <div class="lg:w-1/3 xs:w-1/2 w-full">
                        <div class="border text-xl border-[#C7A22B] p-7 rounded text-center mb-6">
                            {{ $t('projectManagement.assessment') }}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="hidden md:flex justify-center">
            <div class="mb-16 h-[865px] w-[720px] my-auto relative">
                <div
                    class="p-16 w-[387px] h-[387px] top-1/2 left-1/2 absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -ml-[193px] -mt-[193px] text-2xl text-center">
                    {{ $t('projectManagement.component.central') }}
                </div>
                <div
                    class="p-16 w-[185px] h-[185px] top-0 left-1/2 absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -ml-[92px]">
                    {{ $t('projectManagement.component.1') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[70px] left-[53%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ml-[92px]">
                    {{ $t('projectManagement.component.2') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[235px] left-[70%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ml-[92px]">
                    {{ $t('projectManagement.component.3') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[440px] left-[70%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ml-[92px]">
                    {{ $t('projectManagement.component.4') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[615px] left-[53%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ml-[92px]">
                    {{ $t('projectManagement.component.5') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[685px] left-1/2 absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -ml-[92px]">
                    {{ $t('projectManagement.component.6') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[615px] left-[9%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ">
                    {{ $t('projectManagement.component.7') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[440px] -left-[8%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ">
                    {{ $t('projectManagement.component.8') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[235px] -left-[8%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ">
                    {{ $t('projectManagement.component.9') }}</div>
                <div
                    class="p-16 w-[185px] h-[185px] top-[70px] left-[9%] absolute border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white ">
                    {{ $t('projectManagement.component.10') }}</div>
            </div>
        </div>
        <div class="text-center">
            <h3 class="lg:text-4xl text-2xl text-blue mb-10 font-semibold">{{ $t('projectManagement.tools') }}</h3>
        </div>
        <div class="flex items-center justify-center flex-wrap mb-16">
            <div class="w-full sm:w-auto">
                <img src="@/assets/images/asana.png" alt="asana" class="px-0 sm:px-4 mb-4 mx-auto">
            </div>
            <div class="w-full sm:w-auto">
                <img src="@/assets/images/everhour.png" alt="everhour" class="px-0 sm:px-4 mb-4 mx-auto">
            </div>
            <div class="w-full sm:w-auto">
                <img src="@/assets/images/instagati.png" alt="instagati" class="px-0 sm:px-4 mb-4 mx-auto">
            </div>
        </div>
    </div>
    <PayNow :serviceId="4" />
    <!-- <WhatClientsSays /> -->
    <GetInTouch />
    <div class="bg-[#F2F2F2] py-16 mb-10">
        <div class="container">
            <h3 class="lg:text-4xl text-2xl text-blue mb-10 font-semibold">
                {{ $t('projectManagement.idea') }}<br />
                {{ $t('projectManagement.ideaBr') }}
            </h3>
            <button type="button" class="btn-orange">{{ $t('projectManagement.button') }}</button>
        </div>
    </div>

    <ContactsHome />
</template>

<script>
import GetInTouch from "@/components/GetInTouch.vue";
import ContactsHome from "@/components/home/ContactsHome.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from "@/components/WhatClientsSays.vue";
export default {
    name: "ProjectManagement",
    components: {
        ContactsHome,
        PayNow,
        WhatClientsSays,
        GetInTouch,
    }
}
</script>

<style scoped></style>
