<template>
    <footer class="footerProfile">
        <div class="bg-blue py-8">
            <div class="container">
                <div class="flex mxm-15 justify-between flex-wrap flex-col xs:flex-row">
                    <div class="w-full xs:w-1/2 md:w-1/4 mb-4">
                        <h5>{{ $t('footer.management') }}</h5>
                        <router-link to="/">
                            <img src="@/assets/images/logo-footer.png" alt="logo">
                        </router-link>
                    </div>
                    <!-- <div class="w-full xs:w-1/2 md:w-1/4 mb-4">
                        <h5>{{ $t('footer.tools') }}</h5>
                        <ul>
                            <li>
                                <router-link to="/">Lorem ipsum</router-link>
                            </li>
                            <li>
                                <router-link to="/">Lorem ipsum</router-link>
                            </li>
                            <li>
                                <router-link to="/">Lorem ipsum dolor</router-link>
                            </li>
                            <li>
                                <router-link to="/">Lorem ipsum</router-link>
                            </li>
                            <li>
                                <router-link to="/">Lorem ipsum dolor</router-link>
                            </li>
                        </ul>
                    </div> -->
                    <div class="w-full xs:w-1/2 md:w-1/4 mb-4">
                        <h5>{{ $t('footer.services') }}</h5>
                        <ul>
                            <li><router-link to="/business-guidance">{{ $t('menu.businessGuidance') }}</router-link></li>
                            <li><router-link to="/business-audit">{{ $t('menu.businessAudit') }}</router-link></li>
                            <li><router-link to="/accounting">{{ $t('menu.accounting') }}</router-link></li>
                            <li><router-link to="/cfo">{{ $t('menu.cfo') }}</router-link></li>
                            <li><router-link to="/ppwis">{{ $t('menu.ppwis') }}</router-link></li>
                            <li><router-link to="/financial-audit">{{ $t('menu.financialAudit') }}</router-link></li>
                            <li><router-link to="/project-management">{{ $t('menu.projectManagement') }}</router-link></li>
                            <li><router-link to="/crisis-management">{{ $t('menu.crisisManagement') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="w-full xs:w-1/2 md:w-1/4 mb-4">
                        <h5>{{ $t('footer.help') }}</h5>
                        <ul>
                            <li>
                                <router-link to="/">{{ $t('footer.faq') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/">{{ $t('footer.managementSystem') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/">{{ $t('footer.customService') }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-darkBlue py-4">
            <div class="container">
                <div class="flex flex-wrap justify-between mxm-15">
                    <div class="w-full sm:w-auto py-3">{{ new Date().getFullYear() }}. {{ $t('footer.company') }} |
                        {{ $t('footer.rightsReserved') }}</div>
                    <div class="w-full sm:w-auto py-3">
                        <router-link to="/private-politics" class="mr-5">{{ $t('footer.privacyPolicy') }}</router-link>
                        <router-link to="/terms">{{ $t('footer.terms') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "AdminFooter"
}
</script>

