<template>
  <div class="container lg:mt-48 mt-32">

    <div class="mxm-15 flex items-start mb-16">
      <div class="md:w-1/3 w-full flex items-start">
        <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{$t('businessAudit.header')}}</h3>
        <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
      </div>
      <div class="md:w-2/3 w-full">
        <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">{{$t('businessAudit.headerInfo')}}</h1>
        <p class="leading-[22px]">{{$t('businessAudit.headerInfoText')}}</p>
      </div>
    </div>

    <div class="mxm-15 flex items-start mb-8">
      <div class="lg:w-1/2 w-full">
        <h3 class="lg:text-3xl text-2xl font-semibold mb-10 xl:pr-8">{{$t('businessAudit.businessAudit')}}</h3>

        <ul class="leading-[20px] pl-4 list-disc">
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.1')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.2')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.3')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.4')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.5')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.6')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.7')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.8')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.9')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.10')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.11')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.12')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.13')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.14')}}</p></li>
          <li class="mb-2 text-[8px]"><p class="text-sm">{{$t('businessAudit.list.15')}}</p></li>
        </ul>

      </div>
      <div class="lg:w-1/2 w-full">
        <img src="@/assets/icon/audit.svg" alt="arrow">
      </div>
    </div>

    <div class="mxm-15 flex items-start mb-16">
      <div class="lg:w-1/2 w-full">
        <p class="leading-[20px] xl:pr-10">{{$t('businessAudit.auditText')}}</p>
      </div>
      <div class="lg:w-1/2 w-full">
        <p class="leading-[20px] xl:pr-10">{{$t('businessAudit.auditTextSecond')}}</p>
      </div>
    </div>

    <h3 class="lg:text-3xl text-2xl text-center font-semibold mb-12 xl:pr-8">{{$t('businessAudit.elementHeader')}}</h3>

    <div class="lg:flex lg:justify-center block">
      <div class="lg:w-5/6 mxm-15 flex-wrap flex lg:items-stretch mb-16">
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.1')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.2')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.3')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.4')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.5')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.6')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.7')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.8')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.9')}}
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="min-h-[80px] mb-9 rounded-lg border-2 border-[#C7A22B] text-[20px] flex items-center justify-center xl:px-14 lg:px-8 px-4 py-2.5 leading-[26px] text-center">
            {{$t('businessAudit.elements.10')}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <PayNow :serviceId="1" />
  <WhatClientsSays/>
  <ContactsHome></ContactsHome>
</template>

<script>
import WhatClientsSays from '@/components/WhatClientsSays.vue';
import ContactsHome from "@/components/home/ContactsHome.vue";
import PayNow from "@/components/PayNow.vue";
export default {
  name: "BusinessAudit",
  components: {
    ContactsHome,
    PayNow,
    WhatClientsSays,
  }
}
</script>

<style scoped>

</style>
