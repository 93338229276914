<template>
    <div class="flex justify-center mb-20" v-if="service">
        <div class="lg:w-2/6 w-full bg-white rounded form-shadow py-6 px-6 md:py-10 md:px-20">
        <h3>{{ name }}</h3>
        <h4 class="mt-5">{{ service.price }} eur / {{ $t('common.per_hour') }}</h4>
        <p class="mt-5 mb-8">{{ description }}</p>
        
        <Form @submit="payNow">
            <TextField name="hours" type="number" rules="required|min:1|integer" :label="$t('common.hours_count')" :placeholder="$t('common.hours_count')" />
            <button class="btn-yellow w-full">
                {{ $t('form.pay') }}
            </button>
        </Form>
    </div>
    </div>
</template>
<script>
import TextField from '@/components/inputs/TextField.vue';
import axios from 'axios';
import { Form } from 'vee-validate';
import i18n from '@/i18n';
import { useRouter } from 'vue-router';
import { useAuthStore, useCartStore } from '@/store';

export default {
    components: {
        TextField,
        Form,
    },
    props: {
        serviceId: {
            type: String,
        }
    },
    setup() {
        const router = useRouter();
        const cartStore = useCartStore();
        const authStore = useAuthStore();

        return {
            router,
            cartStore,
            authStore,
        }
    },
    computed: {
        name() {
            if(this.service) {
                return this.service.name[i18n.global.locale.value];
            }
        },
        description() {
            if(this.service) {
                return this.service.description[i18n.global.locale.value];
            }
        }
    },  
    data() {
        return {
            service: null,
        }
    },
    methods: {
        async payNow(values, actions) {
            console.log(values)

            this.cartStore.removeAll();

            this.cartStore.add({
                plan_id: this.service.id,
                name: this.name,
                price: this.service.price,
                quantity: values.hours,
                total: values.hours * this.service.price,
                type: 'item',
            });

            this.authStore.setPreviousUrl({
                name: 'payments',
            })

            this.router.push({
                name: 'payments',
            })

            

        },  

        async getPlanById(id) {
            let vm = this;
            await axios.get(`/plan/${id}`).then((response) => {
                vm.service = response.data.data;
            });
        }
    },
    async created() {
        await this.getPlanById(this.serviceId);
    }
}
</script>