<template>
    <div class="white-box mb-6 py-5 px-6">
        <h4>{{ $t('profile.responsiblePerson') }}
            <span class="ml-auto cursor-pointer opacity-70 hover:opacity-100" @click="editFn">
                <svg v-if="editAbout" width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.25 10.9375V13.75H3.0625L11.3575 5.45501L8.545 2.64251L0.25 10.9375ZM13.5325 3.28001C13.825 2.98751 13.825 2.51501 13.5325 2.22251L11.7775 0.467513C11.485 0.175013 11.0125 0.175013 10.72 0.467513L9.3475 1.84001L12.16 4.65251L13.5325 3.28001Z"
                        fill="#6B8A97" />
                </svg>

                <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.75 7.83621L1.31897 13.2672C1.16379 13.4224 0.982759 13.5 0.775862 13.5C0.568966 13.5 0.387931 13.4224 0.232759 13.2672C0.0775861 13.1121 0 12.931 0 12.7241C0 12.5172 0.0775861 12.3362 0.232759 12.181L5.66379 6.75L0.232759 1.31897C0.0775861 1.16379 0 0.982759 0 0.775862C0 0.568966 0.0775861 0.387931 0.232759 0.232759C0.387931 0.0775861 0.568966 0 0.775862 0C0.982759 0 1.16379 0.0775861 1.31897 0.232759L6.75 5.66379L12.181 0.232759C12.3362 0.0775861 12.5172 0 12.7241 0C12.931 0 13.1121 0.0775861 13.2672 0.232759C13.4224 0.387931 13.5 0.568966 13.5 0.775862C13.5 0.982759 13.4224 1.16379 13.2672 1.31897L7.83621 6.75L13.2672 12.181C13.4224 12.3362 13.5 12.5172 13.5 12.7241C13.5 12.931 13.4224 13.1121 13.2672 13.2672C13.1121 13.4224 12.931 13.5 12.7241 13.5C12.5172 13.5 12.3362 13.4224 12.181 13.2672L6.75 7.83621Z"
                        fill="#6B8A97" />
                </svg>

            </span>
        </h4>


        <Form class="my-6 mxm-15 flex flex-wrap" ref="personForm" @submit="onSubmit"
            :initialValues="{ ...authStore.user }">
            <div class="w-full sm:w-1/2">
                <TextField rules="" :label="$t('common.first_name')" name="first_name"
                    :placeholder="$t('common.first_name')" />
            </div>
            <div class="w-full sm:w-1/2">
                <TextField rules="" :label="$t('common.last_name')" name="last_name"
                    :placeholder="$t('common.last_name')" />
            </div>
            <div class="w-full sm:w-1/2">
                <TextField rules="email" :label="$t('common.email')" name="email" :placeholder="$t('common.email')" />
            </div>
            <div class="w-full sm:w-1/2">
                <TextField :label="$t('common.phone_number')" name="phone_number"
                    :placeholder="$t('common.phone_number')" />
            </div>

            <div class="w-full text-right" :class="{ invisible: editAbout }">
                <button class="btn-save" type="submit">{{ $t('form.save') }}</button>
            </div>
        </Form>


    </div>
</template>

<script>
import { Form } from "vee-validate";
import { useAuthStore } from "@/store";
import TextField from "@/components/inputs/TextField.vue";
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    name: "ProfilePerson",
    data() {
        return {
            loading: false,
        }
    },
    setup() {
        const toast = useToast();
        const authStore = useAuthStore();
        return {
            authStore,
            toast,
        }
    },
    components: {
        Form,
        TextField
    },
    methods: {
        async onSubmit(values, actions) {
            let vm = this;
            this.loading = true;
            await axios.post('/user/profile/update', {
                ...values,
            }).then((response) => {
                vm.loading = false;
                if (response) {
                    if (response.data.status === 'success') {
                        vm.authStore.updateLocal(response.data.data);
                        vm.toast.success(response.data.message);
                    } else {
                        vm.toast.error(response.data.message);
                    }
                }

            }).catch((error) => {
                vm.loading = false;
                if (error.response) {
                    if (error.response.data.errors) {
                        for (const key of Object.keys(error.response.data.errors)) {
                            actions.setFieldError(key, error.response.data.errors[key]);
                        }
                    }
                }
            });
        }
    }
}
</script>
