import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import routes from './routes';
import { useAuthStore } from '@/store';

/**
 * Create a new router instance.
 *
 * @param  {Array} routes
 * @return {Router}
 */
 function make (routes) {
	// router 
	const router = createRouter({
		history: createWebHistory(),
		routes
	});


	// Register before guard.
	router.beforeEach(async (to, from, next) => {
		const authStore = useAuthStore();


		next()
	});


	// Register after hook.
	router.afterEach(() => {
		window.scrollTo(0, 0);
	})

	return router
}

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
function beforeEnter (routes, beforeEnter) {
    return routes.map(route => {
        return { ...route, beforeEnter }
    })
}

/**
 * Redirect home if authenticated.
 *
 * @param  {Array} routes
 * @return {Array}
 */
function guestGuard (routes) {
	return beforeEnter(routes, (to, from, next) => {
		const authStore = useAuthStore();
		console.log(to, from);
        if (authStore.user && to.name != 'profile') {
			next({
                name: 'profile',
            })
		} else {
			next();
		}
	})
}

/**
 * Redirect to login if guest.
 *
 * @param  {Array} routes
 * @return {Array}
 */
 function authGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
		const authStore = useAuthStore();

        if (!authStore.user) {
            next({
                name: 'login',
                query: { redirect: to.path }
            })
            
        } else {
            next()
        }
    })
}



const router = make(
    routes({ guestGuard, authGuard })
)

export default router;