<template>
    <li>
        <router-link to="/profile">{{ $t('header.profile') }}</router-link>
    </li>
    <!-- <li>
        <router-link to="/">{{ $t('header.userRoles') }}</router-link>
    </li> -->
    <li>
        <router-link to="/bills">{{ $t('header.bills') }}</router-link>
    </li>
    <li>
        <router-link to="/payments">{{ $t('header.payments') }}</router-link>
    </li>
    <li>
        <router-link to="/reports">{{ $t('common.report')}}</router-link>
    </li>
    <!-- <li>
      <router-link to="/">{{$t('header.settings')}}</router-link>
    </li> -->
    <!-- <li>
        <router-link to="/" class="flex">
            <svg class="mr-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 1H1V10H10V1Z" fill="#F25022" />
                <path d="M10 11H1V20H10V11Z" fill="#00A4EF" />
                <path d="M20 1H11V10H20V1Z" fill="#7FBA00" />
                <path d="M20 11H11V20H20V11Z" fill="#FFB900" />
            </svg>
            {{ $t('header.microsoft') }}
        </router-link>
    </li> -->
    <li>
        <button @click.prevent="logout()" type="button">{{ $t('header.exit') }}</button>
    </li>
</template>

<script>
import { useAuthStore } from '@/store';

export default {
    name: "ProfileNav",
    setup() {
        const authStore = useAuthStore();

        return {
            authStore,
        }
    },
    methods: {
        logout() {
            this.authStore.logout();
        }
    }
}
</script>

<style scoped>

</style>
