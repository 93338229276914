<template>
    <div class="form-group" :class="{'form-group--horizontal' : horizontal }">
        <label
            v-if="label"
            :for="name"
        >{{ label }}
        </label>
        <div class="input">
            <Field :rules="rules" :label="label.toLowerCase()" :name="name" v-model="inner_value" v-slot="{ field, errors, handleChange }">
                <multiselect 
                    @select="valueChanged"
                    @input="valueChanged"
                    :placeholder="placeholder"
                    :value="inner_value"
                    v-model="value" 
                    :options="options" 
                    class="form-control"
                    :class="{'form-control--is-invalid': !!errors.length }"
                    track-by="value"
                    label="name"
                    :searchable="false"
                    :allow-empty="false"
                >
                    <template v-slot:singleLabel="{ option }">
                        <strong>{{ option.name }}</strong>
                    </template>
                </multiselect>
            </Field>
            <ErrorMessage :name="name" v-slot="{ message }">
                <p class="error">{{ message }}</p>
            </ErrorMessage>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import { Field, ErrorMessage } from 'vee-validate';
    // import i18n from '@/i18n';

    export default {
        components: {
            Multiselect,
            Field,
            ErrorMessage,
        },
        props: {
            placeholder: {

            },
            options: {

            },
            name: {
                type: String,
            },
            label: {
                type: String,
                default: "",
            },
            inputValue: {
                type: Object,
            },
            horizontal: {
                type: Boolean,
                default: false,
            },  
            rules: {

            },
        },
        data() {
            return {
                inner_value: "",
                value: { name: 'Pasirinkite', value: '' },
            }
        },
        methods: {
            valueChanged(val, id) {
                console.log(val.value);
                this.inner_value = val.value;
                console.log(val);
            }
        },
        created() {
            console.log(this.inputValue);
            this.inner_value = this.inputValue;
            this.value = this.options.find(option => option.value === this.inputValue);
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
    .form-control {
        margin-top: 5px;
        background: white;
        border: transparent solid 1px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        border: none;
        border: 1px solid $color-grey;
        font-family: 'Montserrat', sans-serif;
        border-radius: 4px;

        &--is-invalid {
            border-color: $color-red;
        }
    }

    .multiselect__tags {
        background: $color-white;
    }

    .multiselect__single {
        background: $color-white;
    }

    .multiselect__option--highlight {
        background: $color-grey;

        &:after {
            background: $color-grey;
        }
    }

    .multiselect__content-wrapper {

    }
</style>
