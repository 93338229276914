<template>
    <div class="container lg:mt-48 mt-32">

        <div class="mxm-15 flex items-start mb-16">
            <div class="md:w-1/3 w-full flex items-start">
                <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('ppwis.header') }}</h3>
                <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
            </div>
            <div class="md:w-2/3 w-full">
                <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">{{ $t('ppwis.headerInfo')
                }}</h1>
                <p class="text-base mb-4">{{ $t('ppwis.headerInfoText') }}</p>
                <p class="text-base mb-8 font-semibold">{{ $t('ppwis.headerInfoTextSecond') }}</p>
            </div>
        </div>

        <div class="mb-16">
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto mb-8">{{ $t('ppwis.forProducer') }}</h3>
            <ul class="leading-[20px] pl-4 list-disc">
                <li class="mb-2 text-[8px]">
                    <p class="text-sm">{{ $t('ppwis.list.1') }}</p>
                </li>
                <li class="mb-2 text-[8px]">
                    <p class="text-sm">{{ $t('ppwis.list.2') }}</p>
                </li>
                <li class="mb-2 text-[8px]">
                    <p class="text-sm">{{ $t('ppwis.list.3') }}</p>
                </li>
                <li class="mb-2 text-[8px]">
                    <p class="text-sm">{{ $t('ppwis.list.4') }}</p>
                </li>
                <li class="mb-2 text-[8px]">
                    <p class="text-sm">{{ $t('ppwis.list.5') }}</p>
                </li>
            </ul>
        </div>

        <div class="mxm-15 flex mb-16">
            <div class="lg:w-1/2 w-full">
                <h3 class="lg:text-4xl mb-8 text-2xl font-semibold w-auto mb-8">{{ $t('ppwis.obligationHeader') }}</h3>
                <p class="mb-8"><span class="font-semibold">{{ $t('ppwis.header') }}</span> {{
                    $t('ppwis.obligationText') }}</p>

                <ul class="leading-[20px] pl-4 list-disc">
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.1') }}</p>
                    </li>
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.2') }}</p>
                    </li>
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.3') }}</p>
                    </li>
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.4') }}</p>
                    </li>
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.5') }}</p>
                    </li>
                    <li class="mb-2 text-[8px]">
                        <p class="text-sm">{{ $t('ppwis.listSecond.6') }}</p>
                    </li>
                </ul>
            </div>
            <div class="lg:w-1/2 w-full">
                <img src="@/assets/icon/ppwis.svg" alt="ppwis">
            </div>
        </div>

        <!-- <PayNow :serviceId="4" /> -->
        <WhatClientsSays />

        <GetInTouch />
    </div>

    <ContactsHome />
</template>

<script>
import ContactsHome from "@/components/home/ContactsHome.vue";
import GetInTouch from "@/components/GetInTouch.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from "@/components/WhatClientsSays.vue";

export default {
    name: "PpwisPage",
    components: {
        GetInTouch,
        ContactsHome,
        PayNow,
        WhatClientsSays,
    }
}
</script>

<style scoped></style>
