import Home from "@/views/HomeView";
import Profile from "@/views/app/ProfileView";
import Login from "@/views/guest/LoginView";
import Registration from "@/views/guest/RegistrationView";
import ForgotPassword from "@/views/guest/ForgotPasswordView";
import ResetPassword from "@/views/guest/ResetPasswordView";
import TermsView from "@/views/TermsView";
import PrivateView from "@/views/PrivateView";
import CompanyView from "@/views/guest/CompanyView";
import PaymentsView from "@/views/app/PaymentsView";
import BusinessGuidance from "@/views/BusinessGuidance.vue";
import BusinessAudit from "@/views/BusinessAudit.vue";
import AccountingPage from "@/views/AccountingPage.vue";
import CfoPage from "@/views/CfoPage.vue";
import PpwisPage from "@/views/PpwisPage.vue";
import FinancialAudit from "@/views/FinancialAudit.vue";
import ProjectManagement from "@/views/ProjectManagement.vue";
import CrisisManagement from "@/views/CrisisManagement.vue";
import FaqView from "@/views/FaqView.vue";
import BillsView from "@/views/app/BillsView.vue";
import ReportView from "@/views/app/ReportView.vue";
import ESSupport from '@/views/ESSupport.vue';

import PaymentSuccess from '@/views/app/payment/Success.vue';
import PaymentFailed from '@/views/app/payment/Failed.vue';
import RunningBusiness from "@/views/RunningBusiness.vue";

export default ({ guestGuard, authGuard }) => [
    ...authGuard([
        {
            path: '/profile',
            name: "profile",
            component: Profile,
            meta: {
                layout: 'profile',
            }
        },
        {
            path: '/payments',
            name: "payments",
            component: PaymentsView,
            meta: {
                layout: 'profile',
            }
        },
        {
            path: '/bills',
            name: "bills",
            component: BillsView,
            meta: {
                layout: 'profile',
            }
        },
        {
            path: '/reports',
            name: "reports",
            component: ReportView,
            meta: {
                layout: 'profile',
            }
        },
    ]),
    ...guestGuard([ 
        {
            path: '/login',
            name: "login",
            component: Login,
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: '/registration',
            name: "registration",
            component: Registration,
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: '/forgot-password',
            name: "forgotPassword",
            component: ForgotPassword,
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
        {
            path: '/password-reset',
            name: "passwordReset",
            component: ResetPassword,
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            }
        },
    ]),
    {
        path: "/verslo-vedimas",
        nane: 'runningBusiness',
        component: RunningBusiness,
    },
    {
        path: "/",
        name: "homePage",
        component: Home,
    },
    {
        path: "/business-guidance",
        name: "businessGuidance",
        component: BusinessGuidance,
    },
    {
        path: "/business-audit",
        name: "businessAudit",
        component: BusinessAudit,
    },
    {
        path: "/accounting",
        name: "accounting",
        component: AccountingPage,
    },
    {
        path: "/cfo",
        name: "cfo",
        component: CfoPage,
    },
    {
        path: "/ppwis",
        name: "ppwis",
        component: PpwisPage,
    },
    {
        path: "/financial-audit",
        name: "financial-audit",
        component: FinancialAudit,
    },
    {
        path: "/project-management",
        name: "project-management",
        component: ProjectManagement,
    },
    {
        path: "/crisis-management",
        name: "crisis-management",
        component: CrisisManagement,
    },
    {
        path: "/faq",
        name: "faq",
        component: FaqView,
    },
    {
        path: '/terms',
        name: "terms",
        component: TermsView,
    },
    {
        path: '/private-politics',
        name: "private",
        component: PrivateView,
    },
    {
        path: '/company',
        name: "company",
        component: CompanyView,
        meta: {
            layout: 'full',
        }
    },
    {
        path: '/es-support',
        name: "essupport",
        component: ESSupport,
    },
    {
        path: '/payment/:id/success',
        name: "payment.success",
        component: PaymentSuccess,
    },
    {
        path: '/payment/:id/canceled',
        name: "payment.failed",
        component: PaymentFailed,
    },
];