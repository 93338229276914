<template>
    <div class="loading" :class="`loading--${color}`">
        <div class="loading__wrapper" :class="{'loading__flex' : flex }">
            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                :width="size" :height="size" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                    <animateTransform attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 25 25"
                        to="360 25 25"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                </path>
            </svg>
            <div v-if="text">{{ textValueChanges[0] }}</div>
        </div>
    </div>
</template>
<script>
    
    export default {
        props: {
            flex: {
                type: Boolean,
                default() {
                    return false;
                },
            }, 
            size: {
                type: String,
                default() {
                    return '40px';
                }
            }, 
            color: {
                type: String,
                default() {
                    return 'yellow';
                }
            },  
            text: {
                type: Boolean,
                default() {
                    return false;
                },
            }
        },
        data() {
            return {
                interval: null,
                textValueChanges: [
                    "Parašome palaukt..."
                ]
            }
        },
        methods: {
            changeText() {
                const first = this.textValueChanges.shift();
                this.textValue = this.textValueChanges.concat(first);
                console.log(this.textValue);

                if(this.textValueChanges.length === 0) {
                    clearInterval(this.interval)
                }
            }
        },
        unmounted() {
            clearInterval(this.interval)
        },
        created() {
            // Kai per ilgai kraunama reikia keisti teksta
            this.interval = setInterval(() => {
                this.changeText();
            }, 5000);
        },
    }
</script>
<style lang="scss" scoped>
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &__flex {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &--yellow {
            svg path,
            svg rect {
                fill: $color-red;
            }
        }

        &--white {
            svg path,
            svg rect {
                fill: $color-white;
            }
        }

        &__wrapper {
            text-align: center;
        }
    }
</style>