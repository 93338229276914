<template>
  <div class="container lg:mt-48 mt-32">
    <div class="text-center">
      <h2 class="text-blue mb-6 font-semibold">{{ $t('common.privacy_policy') }}</h2>
    </div>
    <div>
      <b>{{ $t('privacy_policy.0.header') }}</b>
      <p>{{ $t('privacy_policy.0.content') }}</p>
      <ul>
        <li>{{ $t('privacy_policy.0.list.0.content') }}</li>
        <li>{{ $t('privacy_policy.0.list.1.content') }}</li>
        <li>{{ $t('privacy_policy.0.list.2.content') }}</li>
      </ul>
      
      <b>{{ $t('privacy_policy.1.header') }}</b>
      <p>{{ $t('privacy_policy.1.content') }}</p>
      
      <b>{{ $t('privacy_policy.2.header') }}</b>
      <p>{{ $t('privacy_policy.2.content') }}</p>
      <ul>
        <li>{{ $t('privacy_policy.2.list.0.content') }}</li>
        <li>{{ $t('privacy_policy.2.list.1.content') }}</li>
        <li>{{ $t('privacy_policy.2.list.2.content') }}</li>
        <li>{{ $t('privacy_policy.2.list.3.content') }}</li>
        <li>>{{ $t('privacy_policy.2.list.4.content') }}</li>
      </ul>

      <b>{{ $t('privacy_policy.3.header') }}</b>
      <p>{{ $t('privacy_policy.3.content') }}</p>
      <b>{{ $t('privacy_policy.4.header') }}</b>
      <p>{{ $t('privacy_policy.4.content') }}</p>

      <p>Susisiekite jei turite klausimų.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "PrivateView"
}
</script>

<style lang="scss" scoped>
  ul {
    list-style-type: circle;
    margin-left: 18px;
  }

  b {
    margin-top: 10px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
