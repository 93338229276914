<template>
    <div class="form-group" :class="{'form-group--horizontal' : horizontal }">
        <label
            v-if="label"
            :for="name">
            {{ label }}
        </label>
        <div class="input">
            <div class="help-text" v-if="helpText !== ''">{{ helpText }}</div>
            <Field
                :name="name"
                :model-value="inputValue"
                :rules="rules"
                :label="label.toLowerCase()"
                v-slot="{ field, errors }"
            >
                <input
                    class="form-control"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :max="maxLimit"
                    :min="minLimit"
                    :disabled="disabled"
                    v-bind="field"
                    :class="{'form-control--is-invalid': !!errors.length }"
                />
            </Field>
            <ErrorMessage :name="name" v-slot="{ message }">
                <p class="error">{{ message }}</p>
            </ErrorMessage>
        </div>
    </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';
export default {
components: {
    Field,
    ErrorMessage,
},
props: {
    rules: {},
    helpText: {
        type: [String, Number],
        default: '',
    },
    horizontal: {
        type: Boolean,
        default: false,
    },  
    disabled: {
        type: Boolean,
        default: false
    },
    inputValue: {
        type: [String, Number],
        default: '',
    },
    rows: Number,
    maxLength: Number,
    maxLimit: Number,
    minLimit: Number,
    label: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
    name: {
        type: String,
    },
    rules: {
        type: [Object, String],
        default: "",
    },
    type: {
        type: String,
        default: 'text',
        validator: value => [
            "url",
            "text",
            "password",
            "tel",
            "search",
            "number",
            "email",
            "submit"
        ].includes(value)
    },
    value: {
        type: null,
        default: ""
    }
},
data() {
    return {
        innerValue: '',
    }
},
watch: {
    innerValue(value) {
        this.$emit("input", value);
    },
    value(val) {
        if (val !== this.innerValue) {
            this.innerValue = val;
        }
    },
},

mounted() {
    if (this.inputValue !== null) {
        this.innerValue = this.inputValue
    }
},
}
</script>
<style lang="scss" scoped>
.form-control {
    margin-top: 5px;
    background: #FFFFFF;
    border: transparent solid 1px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    border: none;
    border: 1px solid $color-grey;
    border-radius: 4px;
    padding: 9px 15px;

    &--is-invalid {
        border-color: $color-red;
    }
}
</style>
