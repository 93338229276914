<template>
    <div id="cookie" v-if="!accepted" class="w-auto px-20 py-10">
        <div>
            <div class="w-100 bg-white rounded-lg shadow-lg p-6" style="cursor: auto;">
                <div class="w-16 mx-auto relative -mt-10 mb-3">
                    <img class="-mt-1" src="https://www.svgrepo.com/show/30963/cookie.svg" alt="Cookie Icon SVG">
                </div>
                <span class="w-full sm:w-48  block leading-normal text-gray-800 text-md mb-3">{{ $t('common.we_use_cookies_to_provide_a_better_user_experience') }}</span>
                <div class="flex items-center justify-between gap-3">
                    <router-link :to="{ name: 'private' }" class="text-xs text-gray-400 mr-1 hover:text-gray-800" href="#">{{ $t('common.privacy_policy') }}</router-link>
                    <div class="w-1/2">
                        <button @click="accept()" type="button" class="py-2 px-4 btn-orange focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">{{ $t('common.accept') }}</button>
                    </div>
                    <div class="w-1/2">
                        <button @click="accept()" type="button" class="py-2 px-4 btn-orange text-gray-400 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">{{ $t('common.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            accepted: (JSON.parse(localStorage.getItem('cookie_accept')) ? JSON.parse(localStorage.getItem('cookie_accept')) : false),
        }
    },
    methods: {
        accept() {
            this.accepted = true;
            localStorage.setItem('cookie_accept', true);
        }
    }
}
</script>
<style lang="scss" scoped>
    #cookie {
        position: fixed;
        bottom: 0px;
        right: 0px;
        z-index: 999;
    }
</style>