<template>
	<div class="flex flex-col items-center auth-box">
		<LogoRegistration></LogoRegistration>
		<div class="login-block">
			<div class=" white-box">
				<h4 class="font-semibold mb-6">Atkurti slaptažodį</h4>

				<Form class="mb-4" as="form" ref="forgotForm" :validation-schema="schema" @submit="onSubmit" :initial-values="{
					email: email,
				}">
					<TextField :rules="'required|email'" label="El paštas" name="email" placeholder="El. paštas"/>
					<TextField :rules="'required'" type="password" label="Slaptažodis" name="password" placeholder="Slaptažodis"/>
					<TextField :rules="'required|confirmed:password'" type="password" label="Pakartokite slaptažodį" name="password_confirmation" placeholder="Pakartokite slaptažodį"/>

					<button class="btn-yellow w-full">
						Atkurti slaptažodį
					</button>
				</Form>
			</div>


			<div class="flex flex-row-reverse mb-4 flex-wrap justify-between">
				<router-link to="/registration">{{ $t('auth.toRegistration') }}</router-link>
			</div>

		</div>
	</div>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import { Form } from "vee-validate";
import LogoRegistration from "@/components/ui/LogoRegistration";
import { useToast } from "vue-toastification";
import axios from 'axios';
import { useRoute } from 'vue-router';



export default {
	name: "ForgotPassword",
	components: {
		LogoRegistration,
		Form,
		TextField,
	},
	data() {
		return {
			loading: false,
			token: '',
			email: '',
		}
	},
	setup() {
		const toast = useToast();
		const route = useRoute();
		const router = useRouter();

		return { 
			toast,
			route,
		}
	},
	methods: {
		async onSubmit(values, actions) {
			let vm = this;
			this.loading = true;
			let token = this.token;
			await axios.post('/password/reset', {
				...values,
				token,
			}).then((response) => {
				actions.resetForm();

				if(response) {
					if(response.data.status === 'success') {
						vm.toast.success('Pavyko!', response.data.message);

						vm.router.push({
							name: 'login',
						})

					} else {
						vm.toast.error('Klaida!', response.data.message);
					}
				}
				vm.loading = false;
			}).catch((error) => {
				vm.loading = false;
				if (error.response) {
					if(error.response.data.errors) {
						for (const key of Object.keys(error.response.data.errors)) {
							actions.setFieldError(key, error.response.data.errors[key]);
						}
					} 
				}
			})
		}
	},
	created() {
		console.log(this.route);
		this.token = this.route.query.token;
		this.email = this.route.query.email;
	}
}
</script>

<style scoped>

</style>
