<template>
  <div class="team container pb-6" id="team">
    <h2 class="font-bold my-8">{{$t('lending.team')}}</h2>
    <div class="flex mxm-15">
      <div class="w-full text-center xs:w-1/2 lg:w-1/4">
        <img class="mx-auto" src="@/assets/images/smitas.jpg" alt="">
        <h5>Antanas Šmitas</h5>
        <p><strong>{{$t('lending.auditManager')}}</strong></p>
        <p><strong>{{$t('lending.managementExperience')}}: </strong>14 {{$t('lending.m')}}</p>
        <p><strong>{{$t('lending.specialization')}}: </strong> {{$t('lending.smitasSpec')}}</p>
        <a href="#" class="mx-auto inline-block"><img src="@/assets/icon/in.svg" alt="linkidin"></a>
      </div>
      <div class="w-full text-center xs:w-1/2 lg:w-1/4">
        <img class="mx-auto" src="@/assets/images/pavlavičius.jpg" alt="">
        <h5>Remigijus Pavlavičius</h5>
        <p><strong>{{$t('lending.PavlaviciusExp')}}</strong></p>
        <p><strong>{{$t('lending.managementExperience')}}: </strong>19 {{$t('lending.m')}}</p>
        <p><strong>{{$t('lending.specialization')}}: </strong> {{$t('lending.PavlaviciusSpec')}}</p>
        <a href="#" class="mx-auto inline-block"><img src="@/assets/icon/in.svg" alt="linkidin"></a>

      </div>
      <div class="w-full text-center xs:w-1/2 lg:w-1/4">
        <img class="mx-auto" src="@/assets/images/augustėnas.jpg" alt="">
        <h5>Justas Augustėnas</h5>
        <p><strong>{{$t('lending.AugustenasExp')}}</strong></p>
        <p><strong>{{$t('lending.managementExperience')}}: </strong>14 {{$t('lending.m')}}</p>
        <p><strong>{{$t('lending.specialization')}}: </strong>{{$t('lending.AugustenasSpec')}}</p>
        <a href="#" class="mx-auto inline-block"><img src="@/assets/icon/in.svg" alt="linkidin"></a>
      </div>
      <div class="w-full text-center xs:w-1/2 lg:w-1/4">
        <img class="mx-auto" src="@/assets/images/surkys.jpg" alt="">
        <h5>Antanas Šmitas</h5>
        <p><strong>{{$t('lending.SurkysExp')}}</strong></p>
        <p><strong>{{$t('lending.managementExperience')}}: </strong>14 {{$t('lending.m')}}</p>
        <p><strong>{{$t('lending.specialization')}}: </strong>{{$t('lending.SurkysSpec')}}</p>
        <a href="#" class="mx-auto inline-block"><img src="@/assets/icon/in.svg" alt="linkidin"></a>
      </div>
    </div>

  </div>
  <section class="sun">
    <div class="d-flex justify-content-end cloud-bg">
      <img src="@/assets/images/cloud.png" alt="cloud" class="ml-auto">
    </div>
  </section>
</template>

<script>
export default {
  name: "TeamDream"
}
</script>

<style scoped>

</style>
