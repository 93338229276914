<template>
  <div class="profile-wrapper flex flex-col min-h-screen">
    <ProfileMenu></ProfileMenu>
    <div class="flex max-w-full grow">

      <ProfileSidebar/>

      <div class="grow px-5 mt-4 profile relative py-4">
        <router-view></router-view>
      </div>
    </div>

    <ProfileFooter/>
  </div>
</template>

<script>
import ProfileMenu from "@/components/profile/ProfileMenu";
import ProfileSidebar from "@/components/profile/ProfileSidebar.vue";
import ProfileFooter from "@/components/profile/ProfileFooter.vue";

export default {
  name: "LayoutProfile",
  components: {ProfileSidebar, ProfileMenu, ProfileFooter}
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/_layoutProfile.scss";
</style>
