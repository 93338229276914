<template>
  <div class="flex flex-col items-center h-full ">
    <div class="w-full flex justify-between px-4 pt-2 items-center flex-col sm:flex-row">
        <LogoRegistration class="my-3"></LogoRegistration>
        <div class="my-3"><p class="font-medium">{{$t('auth.companyToRegistration')}} <router-link to="/registration">{{$t('auth.companyHref')}}</router-link></p></div>
    </div>
    <div class="login-block">
      <div class="white-box flex items-center flex-col sm:flex-row">
        <svg class="pr-4 min-w-max mb-3" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM12 22.5L4.5 15L6.615 12.885L12 18.255L23.385 6.87L25.5 9L12 22.5Z" fill="#41C72B"/>
        </svg>
        <p class="mb-3">{{$t('auth.companyCongratulations')}}
          <strong>vardenispavardenis@gmail.com</strong>!</p>
      </div>
      <div class="white-box">
        <h4 class=" mb-6">{{$t('auth.companyH')}}</h4>

        <Form class="mb-4" as="form" ref="companyForm" @submit="checkRegistration">

          <Field as="div"
                 class="form-block"
                 name="name"
                 v-slot="{ field, meta }"
                 v-model="company.name"
                 rules="max:255|required"
                 :label="$t('form.companyName')"
          >
            <input
                type="text"
                v-bind="field"
                :placeholder="$t('form.companyName')"
                :class="inputCheck(meta)"
            >
            <ErrorMessage name="name" class="errorMess"></ErrorMessage>
          </Field>

          <Field as="div"
                 class="form-block relative"
                 name="you"
                 v-slot="{ field, meta }"
                 v-model="company.activity"
                 rules="required"
                 :label="$t('form.companyActivity')"
          >
            <select
                v-bind="field"
                :class="inputCheck(meta)"
            >
              <option value="" disabled>{{$t('form.companyActivity')}}</option>
              <option value="Organization">Organization</option>
              <option value="Human">Human</option>
            </select>
            <div class="appearance"></div>
            <ErrorMessage name="you" class="errorMess"></ErrorMessage>
          </Field>
          <button class="btn-yellow w-full">
            {{$t('form.companySend')}}
          </button>

        </Form>

      </div>
    </div>

  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import LogoRegistration from "@/components/ui/LogoRegistration";

export default {
  name: "CompanyView",
  components: {
    LogoRegistration,
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      company: {
        name: '',
        activity: ''
      },
    }
  },
  methods: {
    checkRegistration(){
      this.$refs.companyForm.validate().then(success => {
        if (success) {
          console.log(this.company)
        }
      })
    },
    inputCheck(s){
      if (s.touched && s.valid) {
        return 'input-green'
      } else if (s.touched && !s.valid) {
        return 'input-danger'
      }
    }
  }
}
</script>

<style scoped>

</style>
