import axios from 'axios'
import i18n from '@/i18n';
import { useAuthStore } from '@/store';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(request => {
    const authStore = useAuthStore();
    request.headers.common['Accept-Language'] = i18n.global.locale.value;
    if (authStore.token) {
        request.headers.common['Authorization'] = `Bearer ${authStore.token}`
    }
    return request
});

axios.interceptors.response.use(response => response, async (error) => {
    console.log(error.response.status);
    const authStore = useAuthStore();

    if(error.response.status === 401) {

        authStore.updateToken();
    }

    return Promise.reject(error)
})
