<template>
  <div class="aboutUs container" id="aboutUs"
  >
    <div class="flex flex-col lg:flex-row mxm-15">
      <div class="w-full flex lg:block xl:w-1/4 lg:w-1/3">
        <h3 class="nav-link pr-5"
            @click="showAbout = true"
            :class="{active: showAbout}"
        >
          {{$t('lending.abouts')}}
        </h3>
        <h3 class="nav-link pr-5"
            @click="showAbout = false"
            :class="{active: !showAbout}">
          {{$t('lending.values')}}
        </h3>
      </div>
      <div class="w-full xl:w-3/4 lg:w-2/3">
        <div v-if="showAbout">
          <p>
            <span class="font-bold">Light Financials</span> - {{$t('lending.aboutT1')}}
          </p>
          <p>{{$t('lending.aboutT2')}}</p>
          <p>{{$t('lending.aboutT3')}}</p>
          <p>{{$t('lending.aboutT4')}}</p>
          <p>{{$t('lending.aboutT5')}}</p>
          <p class="quote">{{$t('lending.aboutT6')}}</p>
        </div>
        <div v-else>1

          <p>
            <span class="font-bold">Light Financials</span> - {{$t('lending.aboutT1')}}
          </p>
          <p>{{$t('lending.aboutT2')}}</p>
          <p>{{$t('lending.aboutT3')}}</p>
          <p>{{$t('lending.aboutT4')}}</p>
          <p>{{$t('lending.aboutT5')}}</p>
          <p class="quote">{{$t('lending.aboutT6')}}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      showAbout: true,
    }
  },
}
</script>

<style scoped>

</style>
