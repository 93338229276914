<template>
	<div class="flex flex-col items-center auth-box">
		<LogoRegistration></LogoRegistration>
		<div class="login-block">
			<div class=" white-box">
				<h4 class="font-semibold mb-6">{{ $t('auth.forgotH') }}</h4>
				<Form class="mb-4" as="form" ref="forgotForm" :validation-schema="schema" @submit="onSubmit">
					<TextField rules="required" name="email" :label="$t('form.email')" :placeholder="$t('form.email')" />

					<button class="btn-yellow w-full">
						{{ $t('form.forgotSend') }}
					</button>
				</Form>
			</div>


			<div class="flex flex-row-reverse mb-4 flex-wrap justify-between">
				<router-link to="/registration">{{ $t('auth.toRegistration') }}</router-link>
			</div>

		</div>
	</div>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import { Form } from "vee-validate";
import LogoRegistration from "@/components/ui/LogoRegistration";
import { useToast } from "vue-toastification";
import axios from 'axios';

export default {
	name: "ForgotPassword",
	components: {
		LogoRegistration,
		Form,
		TextField,
	},
	data() {
		return {
			loading: false,
		}
	},
	setup() {
		const toast = useToast();

		return { toast }
	},
	methods: {
		async onSubmit(values, actions) {
			let vm = this;
			vm.loading = true;

			await axios.post('/password/email', {
				...values,
			}).then((response) => {

				if(response) {
					if(response.data.status === 'success') {
						actions.resetForm();
						vm.toast.success('Pavyko!', response.data.message);
					} else {
						vm.toast.error('Klaida!', response.data.message);
					}
				}
				vm.loading = false;

			}).catch((error) => {
				vm.loading = false;
				if (error.response) {
					if(error.response.data.errors) {
						for (const key of Object.keys(error.response.data.errors)) {
							actions.setFieldError(key, error.response.data.errors[key]);
						}
					} 
				}
			});
		},
	}
}
</script>

<style scoped>

</style>
