<template>
  <router-link :to="{ name: 'homePage' }">
    <img src="@/assets/images/logo-registration.png" alt="logo">
  </router-link>
</template>

<script>
export default {
  name: "LogoRegistration"
}
</script>

