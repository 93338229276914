<template>
    <div class="container lg:mt-48 mt-32">
        <div class="mxm-15 flex items-start mb-16">
            <div class="md:w-1/3 w-full flex items-start">
                <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">Verslo vedimas</h3>
                <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
            </div>
            <div class="md:w-2/3 w-full">
                <h2 class="lg:text-2xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">Light Financials – verslo
                    konsultavimo įmonė, nuosekliai vedanti verslą ir jo savininką į skaidrumą, stabilumą ir vidinę
                    ramybę. Įsivaizduokite įmonę, sukurtą skirtingų sričių profesionalų. Žmonių, jaučiančių rinkos
                    pulsą. Gebančių į kiekvieną klausimą ar problemą pažvelgti kitu kampu. </h2>
                <p class="text-base mb-4">Mes nesivadovaujame tik sausa tvarka ar suasmenintu požiūriu, bet ieškome
                    santykio tarp spontaniškumo ir tikslumo. Ieškome ne klientų, o ilgalaikių partnerių kiekviename.
                </p>
                <p class="text-base mb-8 ">Ar tikrai žinote tai, ko Jūsų verslui iš tikrųjų reikia? Kas Jus
                    paverstų visapusiškai sėkmingu? Koncentruotai, vienoje vietoje organizuojamas verslas užtikrina
                    skaidrumą verslo vadovui. </p>
                <p class="text-base mb-4">Tinkamos partnerystės dėka ne tik jaučiama pagalba visose situacijose, bet ir
                    aiškiai matomas veiklos potencialas rinkoje
                </p>
                <p class="text-base mb-4">Subūrėme skirtingų sričių profesionalus, kurių dėka užtikriname visas tinkamo
                    verslo valdymui reikalingas paslaugas.
                </p>
                <p class="text-base font-semibold">
                    Mums klientai tampa partneriais, o verslas jiems gyvenimu!
                </p>
            </div>
        </div>
    </div>
</template>