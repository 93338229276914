<template>

	<div class="pb-8 shrink bg-blue lg:block hidden">
		<div class="profile-sidebar lg:sticky lg:top-0 lg:w-[290px]">
			<h4 class="bg-yellow p-5 flex text-[15px]">
				<span class="mr-3 pt-0.5">
					<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 9.90851H10" stroke="white" stroke-width="2" stroke-linecap="round" />
						<path d="M1 5.45422H10" stroke="white" stroke-width="2" stroke-linecap="round" />
						<path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round" />
					</svg>
				</span>
				{{ authStore.user.business_name }}
			</h4>
			<nav class="list-none p-5 profile-menu">
				<ProfileNav />
			</nav>
		</div>
	</div>
</template>

<script>
import ProfileNav from "@/components/ui/ProfileNav.vue";
import { useAuthStore } from '@/store';

export default {
	name: "ProfileSidebar",
	components: { ProfileNav },
	setup() {
		const authStore = useAuthStore();
		return {
			authStore,
		}
	}
}
</script>

<style scoped>

</style>
