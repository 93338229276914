<template>
    <div class="container lg:mt-48 mt-32">

        <div class="mxm-15 flex items-start mb-16">
            <div class="md:w-1/2 w-full flex items-start">
                <h3 class="lg:text-4xl text-2xl text-blue font-semibold lg:w-52 w-auto">{{ $t('businessGuidance.header')
                    }}
                </h3>
                <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
            </div>
            <div class="md:w-1/2 w-full">
                <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] font-semibold">
                    {{ $t('businessGuidance.headerInfo') }}</h1>
            </div>
        </div>
        <div class="mb-20">
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold">{{ $t('businessGuidance.youKnow') }}</h3>
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold">{{ $t('businessGuidance.youKnowSecond') }}</h3>
        </div>

        <div class="mxm-15 flex items-start mb-20">
            <div class="md:w-1/2 w-full">
                <p class="mb-4 leading-[1.4]">{{ $t('businessGuidance.textOne') }}</p>
                <p class="mb-4 leading-[1.4]">{{ $t('businessGuidance.textTwo') }}</p>
                <p class="mb-4 leading-[1.4]">{{ $t('businessGuidance.textThree') }}</p>
                <p class="mb-4 leading-[1.4]">{{ $t('businessGuidance.textFour') }}</p>
            </div>
            <div class="md:w-1/2 w-full ">
                <img src="@/assets/icon/target.svg" alt="target" class="lg:px-4 mx-auto">
            </div>
        </div>

        <div class="xl:w-3/4 mx-auto">
            <div class="mxm-15 flex mb-10 justify-between">
                <div class="sm:w-1/2 lg:w-2/5 w-full text-center mb-8">
                    <img src="@/assets/icon/icon.svg" class="inline-block" alt="icon">
                    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mt-5 mb-7">{{ $t('businessGuidance.growthH')
                        }}
                    </h3>
                    <p class="leading-[20px]">{{ $t('businessGuidance.growthT') }}</p>
                </div>
                <div class="sm:w-1/2 lg:w-2/5 w-full text-center mb-8">
                    <img src="@/assets/icon/icon2.svg" class="inline-block" alt="icon">
                    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mt-5 mb-7">{{
                        $t('businessGuidance.progressH') }}
                    </h3>
                    <p class="leading-[20px]">{{ $t('businessGuidance.progressT') }}</p>
                </div>
                <div class="sm:w-1/2 lg:w-2/5 w-full text-center mb-8">
                    <img src="@/assets/icon/icon3.svg" class="inline-block" alt="icon">
                    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mt-5 mb-7">{{
                        $t('businessGuidance.successH') }}
                    </h3>
                    <p class="leading-[20px]">{{ $t('businessGuidance.successT') }}</p>
                </div>
                <div class="sm:w-1/2 lg:w-2/5 w-full text-center mb-8">
                    <img src="@/assets/icon/icon4.svg" class="inline-block" alt="icon">
                    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mt-5 mb-7">
                        {{ $t('businessGuidance.partnershipH') }}</h3>
                    <p class="leading-[20px]"><span class="font-bold">Light
                            Financials</span>{{ $t('businessGuidance.partnershipT') }}</p>
                </div>
            </div>
        </div>

        <PayNow :serviceId="2" />
        <WhatClientsSays />

    </div>
    <ContactsHome></ContactsHome>
</template>

<script>
import ContactsHome from "@/components/home/ContactsHome.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from "@/components/WhatClientsSays.vue";

export default {
    name: "BusinessGuidance",
    components: {
        ContactsHome,
        PayNow,
        WhatClientsSays,
    }
}
</script>

<style scoped></style>
