<template>
  <div class="bg-gradient">
    <component :is="layout">
      <router-view/>
    </component>
    <Cookie></Cookie>
  </div>
</template>

<script>
import {RouterView} from "vue-router";
import LayoutFull from "@/layouts/LayoutFull";
import LayoutMain from "@/layouts/LayoutMain";
import LayoutProfile from "@/layouts/LayoutProfile";
import Cookie from "@/components/Cookie.vue";

export default {
  name: 'App',

  components: {
    LayoutFull,
    LayoutMain,
    LayoutProfile,
    RouterView,
    Cookie,
  },

  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') {
        return 'layout-full';
      }
      if (this.$route.meta.layout === 'profile') {
        return 'layout-profile';
      }

      return 'layout-main'
    }
  }
}
</script>
