<template>
	<div class="flex mxm-15 justify-center flex-wrap">
		<div class="w-full lg:w-[745px] xl:w-[1500px] lg:order-2 profile-box">
			<div class="flex mxm-15 flex-wrap">
				<div class="xl:w-2xl w-full xl:ml-auto">
					<h3>{{ $t('common.report') }}</h3>
					<br>
                    <iframe width="100%" height="600px" :src="authStore.user.report_url" frameborder="0"></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useAuthStore } from '@/store'

export default {
	name: "ProfileView",
	components: {

	},
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        }
    }
}
</script>
