import {defineRule, configure} from 'vee-validate'
import {localize, setLocale} from '@vee-validate/i18n';

import lt from '@vee-validate/i18n/dist/locale/lt.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import ru from '@vee-validate/i18n/dist/locale/ru.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';

import i18n from '@/i18n';
import { watch, ref } from '@vue/runtime-core';
import AllRules from '@vee-validate/rules';

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

configure({
    generateMessage: localize({
        lt,
        en,
        ru,
        fr,
    }),
});

const locale = ref(i18n.global.locale);
setLocale('lt');

watch(locale, () => {
    console.log('locale', locale.value);
    setLocale(locale.value);
});


// setLocale('lt');
