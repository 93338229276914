<template>
  <div class="min-h-screen firstDesktop relative ">
    <!-- <video class="background-video" autoplay loop muted id="first">
      <source src="@/assets/videos/headerVideo.mp4" type="video/mp4">
    </video> -->
    <img class="background-video" src="@/assets/heroimg.jpg" alt="">
    <div class="container relative flex flex-col min-h-screen pt-20 pb-6 z-10 justify-center">
      <div class="xl:w-5/6 xl:mx-auto">
        <h1 class="md:w-7/12 text-white font-bold">{{ $t('lending.h1') }}</h1>

        <div class="lg:flex hidden justify-end menu ">
          <div class="md:w-1/3">
            <router-link to="/verslo-vedimas">
              <p>{{ $t('lending.businessManagement') }}</p>
            </router-link>
          </div>
        </div>
        <div class="lg:flex hidden  justify-center menu">
          <div class="md:w-1/3">
            <p>{{ $t('lending.businessConsulting') }}
            </p>

            <ul>
              <li><router-link to="/business-audit">/ {{ $t('lending.businessAudit') }}</router-link></li>
              <li><router-link to="/business-guidance">/ {{ $t('lending.businessAdministration') }}</router-link></li>
              <li><router-link to="/project-management">/ {{ $t('lending.projectManagement') }}</router-link></li>
              <!-- <li><router-link to="/pwis">/ {{ $t('lending.managementTools') }}</router-link></li> -->
            </ul>
          </div>
        </div>
        <div class="lg:flex hidden  menu">
          <div class="md:w-1/3">
            <p>{{ $t('lending.financeServices') }}</p>
            <ul>
              <!-- <li><router-link to="/business-guidance">/ {{ $t('lending.establishmentCompanies') }}</router-link></li> -->
              <li><router-link to="/accounting">/ {{ $t('lending.accounting') }}</router-link></li>
              <li><router-link to="/cfo">/ {{ $t('lending.financier') }}</router-link></li>
              <li><router-link to="/financial-audit">/ {{ $t('lending.financialAudit') }}</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FirstDesktop"
}
</script>
