<template>
  <section class="business">
    <div class="container text-center" id="Services">
      <h2 class="h2">{{ $t('lending.businessH') }}</h2>
      <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="'auto'" :pagination="true"
        :loop="true" :navigation="true" :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }" :modules="modules" class="mySwiper swiper swiperPhoto" :breakpoints="{
          320: {
            slidesPerView: 1,
          },
          669: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          }
        }">
        <swiper-slide class="swiper-slide"><img src="@/assets/images/photo.jpg" alt=""></swiper-slide>
        <swiper-slide class="swiper-slide"><img src="@/assets/images/photo.jpg" alt=""></swiper-slide>
        <swiper-slide class="swiper-slide"><img src="@/assets/images/photo.jpg" alt=""></swiper-slide>
        <swiper-slide class="swiper-slide"><img src="@/assets/images/photo.jpg" alt=""></swiper-slide>
        <swiper-slide class="swiper-slide"><img src="@/assets/images/photo.jpg" alt=""></swiper-slide>

      </swiper>

    </div>


    <div class="container text-center strategy">
      <div>
        <h4>{{ $t('lending.strategyH') }}</h4>
        <!-- <p>{{$t('lending.strategyP')}}</p> -->
        <br>
        <br>
        <a href="#" class="btn-orange mt-20">{{ $t('lending.strategyBtn') }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Navigation } from "swiper";

export default {
  name: "serviceBlock",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination, Navigation],
    };
  }
}
</script>
<style lang="scss" scoped>
.mt-20 {
  margin-top: 20px !important;
}
</style>