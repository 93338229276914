import { defineStore } from 'pinia';
// import { useRouter } from 'vue-router';
import axios from 'axios';
import router from '@/router';


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        token: JSON.parse(localStorage.getItem('token')),
        returnUrl: null
    }),
    actions: {
        async updateLocal(data) {
            this.user = data;
            localStorage.setItem('user', JSON.stringify(this.user));
        },

        setAuth(user, token) {
              // update pinia state
              this.user = user;
              this.token = token;

              // store user details and jwt in local storage to keep user logged in between page refreshes
              localStorage.setItem('token', JSON.stringify(token));
              localStorage.setItem('user', JSON.stringify(user));
        },
        async updateToken() {
            if(this.user) {
                console.log(`Trying update token`);
                await axios.get('/refresh-token', {

                }).then((response) => {
                    if(response.data.status === 'success') {
                        localStorage.setItem('token', JSON.stringify(response.data.access_token));
                        location.reload();
                    } else {
                        console.log(`Failed to refresh token error: ${error.message}`);

                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        // const router = useRouter();
                        router.push({
                            name: 'login',
                        })
                    }
                }).catch((error) => {
                    // const router = useRouter();
                    console.log(`Failed to refresh token error: ${error.message}`);
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');

                    router.push({
                        name: 'login',
                    })
                });
            }
        },
        isAuth() {
            return this.user;
        },
        setPreviousUrl(url) {
            this.returnUrl = url;
        },
        logout() {
            // const router = useRouter();
            this.user = null;
            this.token = null;
            
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            
            router.push({
                name: 'login'
            });
        }
    }
});
