<template>
  <div class="container lg:mt-48 mt-32">

    <div class="mxm-15 flex items-start mb-16">
      <div class="md:w-1/3 w-full flex items-start">
        <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('accounting.header') }}</h3>
        <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
      </div>
      <div class="md:w-2/3 w-full">
        <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">{{ $t('accounting.headerInfo') }}</h1>
        <p class="text-base mb-6">{{ $t('accounting.accountingText') }}</p>
        <p class="text-base mb-6">{{ $t('accounting.accountingTextSecond') }}</p>
        <p class="text-base mb-6">{{ $t('accounting.accountingTextThree') }}</p>
      </div>
    </div>

    <div class="mxm-15 flex items-start mb-12">
      <div class="lg:w-1/2 w-full">
        <h3 class="lg:text-4xl text-2xl text-blue mb-6 font-semibold w-auto">{{ $t('accounting.whatHeader') }}</h3>
        <p>{{ $t('accounting.whatText') }}</p>
      </div>
    </div>

    <div class="mxm-15 flex items-start mb-16">
      <div class="lg:w-1/2 w-full text-center">
        <h2 class="lg:text-[200px] text-[140px] lg:leading-[200px] leading-[140px] mb-0 font-semibold w-auto yellow">30%
        </h2>
        <p class="text-[21px] font-medium leading-[1.2]">{{ $t('accounting.whatTextState') }}</p>
      </div>
      <div class="lg:w-1/2 w-full text-center">
        <h2 class="lg:text-[200px] text-[140px] lg:leading-[200px] leading-[140px] mb-0 font-semibold w-auto yellow">70%
        </h2>
        <p class="text-[21px] font-medium leading-[1.2]">{{ $t('accounting.whatTextCeo') }}</p>
      </div>
    </div>

    <div class="relative md:flex justify-center mb-20">
      <img src="@/assets/icon/accounting.svg" alt="accounting">
      <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto md:absolute top-0 md:left-[60%] md:mt-20 mt-8">
        {{ $t('accounting.helpYou') }}</h3>
    </div>

  </div>

  <div class="bg-[#F2F2F2] py-16">
    <div class="container">
      <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('accounting.accountingServices') }}</h3>
      <div class="mxm-15 flex items-start mb-2">
        <div class="lg:w-1/2 w-full">
          <ul class="leading-[20px] max-w-[410px] pl-4 list-disc">
            <li class="mb-2 text-[8px]">
              <p class="text-sm">{{ $t('accounting.list.1') }}</p>
            </li>
            <li class="mb-2 text-[8px]">
              <p class="text-sm">{{ $t('accounting.list.2') }}</p>
            </li>
          </ul>
        </div>
        <div class="lg:w-1/2 w-full">
          <ul class="leading-[20px] max-w-[410px] pl-4 list-disc">
            <li class="mb-2 text-[8px]">
              <p class="text-sm">{{ $t('accounting.listSecond.1') }}</p>
            </li>
            <li class="mb-2 text-[8px]">
              <p class="text-sm">{{ $t('accounting.listSecond.2') }}</p>
            </li>
            <li class="mb-2 text-[8px]">
              <p class="text-sm">{{ $t('accounting.listSecond.3') }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container pt-16">
    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('accounting.softwareHeader') }}</h3>
    <div class="mxm-15 flex items-start mb-14">
      <div class="md:w-1/2 w-full">
        <p class="mb-10 lg:w-[92%]">
          <span class="font-semibold">{{ $t('accounting.softwareTextBold') }}</span> {{ $t('accounting.softwareText') }}
        </p>
        <p class="font-semibold mb-3">{{ $t('accounting.softwareListHeader') }}</p>
        <ul class="leading-[20px] max-w-[410px] pl-4 list-disc mb-6">
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.1') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.2') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.3') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.4') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.5') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listThree.6') }}</p>
          </li>
        </ul>
      </div>
      <div class="md:w-1/2 w-full">
        <img src="@/assets/images/microsoft.png" alt="microsoft">
        <p class="pb-6">{{ $t('accounting.softwareTextThree') }}</p>
      </div>
    </div>
    <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('accounting.powerBiHeader') }}</h3>
    <div class="mxm-15 flex items-start mb-14">
      <div class="md:w-1/2 w-full">
        <p class="mb-4 lg:w-[92%]">
          <span class="font-semibold">{{ $t('accounting.powerBiTextBold') }}</span> {{ $t('accounting.powerBiText') }}
        </p>
        <p class="mb-4">{{ $t('accounting.powerBiTextSecond') }}</p>
        <p class="font-semibold mb-3">{{ $t('accounting.powerBiListHeader') }}</p>
        <ul class="leading-[20px] max-w-[410px] pl-4 list-disc mb-6">
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listFour.1') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listFour.2') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listFour.3') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{ $t('accounting.listFour.4') }}</p>
          </li>
          <li class="mb-2 text-[8px]">
            <p class="text-sm">{{$t('accounting.listFour.5')}}</p>
        </li>
        <li class="mb-2 text-[8px]">
          <p class="text-sm">{{$t('accounting.listFour.6')}}</p>
        </li>
      </ul>
    </div>
    <div class="md:w-1/2 w-full">
      <img src="@/assets/images/Power-BI.jpg" alt="Power-BI">
    </div>
  </div>
  <PayNow :serviceId="7" />
  <WhatClientsSays/>
  <GetInTouch />

</div>

<ContactsHome /></template>

<script>
import GetInTouch from "@/components/GetInTouch.vue";
import ContactsHome from "@/components/home/ContactsHome.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from '@/components/WhatClientsSays.vue';

export default {
  name: "AccountingPage",
  components: {
    ContactsHome,
    GetInTouch,
    PayNow,
    WhatClientsSays,
  }
}
</script>

<style scoped></style>
