import * as yup from 'yup';

yup.setLocale({
    mixed: {
      required: '${path} yra būtinas laukas',
      oneOf: '${path} turi būti iš šių verčių: ${values}',
      notOneOf: '${path} neturi būti iš šių verčių: ${values}',
    },
    number: {
      min: '${path} turi būti daugiau arba lygu ${min}',
      max: '${path} turi būti mažiau arba lygu ${max}',
      lessThan: '${path} turi būti mažiau nei ${less}',
      moreThan: '${path} turi būti daugiau nei ${more}',
      positive: '${path} turi būti teigiamas skaičius',
      negative: '${path} turi būti neigiamas skaičius',
      integer: '${path} turi būti sveikasis skaičius',
    },
    string: {
      length: '${path} turi būti iš ${length} simbolių',
      min: '${path} turi būti bent ${min} simboliai',
      max: '${path} turi būti daugiausia iš ${max} simbolių',
      matches: '${path} turi atitikti šiuos dalykus: „${regex}“',
      email: '${path} turi būti galiojantis el. adresas',
      url: '${path} turi būti galiojantis URL',
      trim: '${path} turi būti be tarpų pradžioje ir gale',
      lowercase: '${path} turi būti tik mažosios raidės',
      uppercase: '${path} turi būti tik didžiosios raidės',
    },
    date: {
      min: '${path} turi būti vėliau nei ${min}',
      max: '${path} turi būti anksčiau nei ${max}',
    },
});

export default yup;