import {createApp} from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router';
import './plugins/index'; 
import i18n from './i18n'

//3rd party plugins
import '@/libs/vee-validate';
import Toast from "vue-toastification";
import toastOptions from '@/libs/toastification';
import VueSmoothScroll from 'vue3-smooth-scroll'

// import main styles
import "@/assets/scss/main.scss";
import "vue-toastification/dist/index.css";

const app = createApp(App)

app.use(router)
    // .use(store)
    .use(i18n)
    .use(createPinia())
    .use(VueSmoothScroll, {
        duration: 700,
        updateHistory: false
    })
    .use(Toast, toastOptions)
    .mount('#app')


