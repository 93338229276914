<template>
	<div class="white-box mb-6 py-5 px-6">
		<h4>{{ $t('profile.address') }}
			<span class="ml-auto cursor-pointer opacity-70 hover:opacity-100" @click="editAbout = !editAbout">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0.25 10.9375V13.75H3.0625L11.3575 5.45501L8.545 2.64251L0.25 10.9375ZM13.5325 3.28001C13.825 2.98751 13.825 2.51501 13.5325 2.22251L11.7775 0.467513C11.485 0.175013 11.0125 0.175013 10.72 0.467513L9.3475 1.84001L12.16 4.65251L13.5325 3.28001Z"
						fill="#6B8A97" />
				</svg>
			</span>
		</h4>

		<Form class="my-6 mxm-15 flex flex-wrap" ref="addressForm" @submit="onSubmit" :initialValues="{ ...authStore.user }">
			<div class="w-full sm:w-1/2">
                <TextField :label="$t('common.address')" name="address" :placeholder="$t('common.address')"/>
            </div>
			<div class="w-full sm:w-1/2">
                <TextField :label="$t('common.apartment')" name="apartment" :placeholder="$t('common.apartment')"/>
            </div>
			<div class="w-full sm:w-1/2">
                <TextField :label="$t('common.city')" name="city" :placeholder="$t('common.city')"/>
            </div>
			<div class="w-full sm:w-1/2">
                <TextField :label="$t('common.country')" name="country" :placeholder="$t('common.country')"/>
            </div>

			
			<div class="w-full text-right">
				<button class="btn-save" type="submit">{{ $t('form.save') }}</button>
			</div>

		</Form>
	</div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useAuthStore } from "@/store";
import TextField from "@/components/inputs/TextField.vue";
import yup from '@/plugins/yup.js';
import { useToast } from 'vue-toastification';
import axios from "axios";


export default {
	name: "ProfileAddress",
	data() {
		return {
			loading: false,
		}
	},
	setup() {
		const authStore = useAuthStore();
		const toast = useToast();
		return {
			authStore,
			toast,
		}
	},
	components: {
		TextField,
		Form,
		Field,
		ErrorMessage
	},
	methods: {
		async onSubmit(values, actions) {
			let vm = this;

            await axios.post('/user/address/update', {
                ...values,
            }).then((response) => {
                if(response) {
                    if(response.data.status === 'success') {
						vm.authStore.updateLocal(response.data.data);
                        vm.toast.success(response.data.message);
                    } else {
						vm.toast.error(response.data.message);
					}
                }
            }).catch((error) => {
                if (error.response) {
                    if(error.response.data.errors) {
                        for (const key of Object.keys(error.response.data.errors)) {
                            actions.setFieldError(key, error.response.data.errors[key]);
                        }
                    } 
                }
            });
		},
	}

}
</script>
