<template>
    <div class="container lg:mt-48 mt-32">
        <div class="mxm-15 flex items-stretch mb-20">
            <div class="md:w-1/2 w-full flex mb-4 justify-between flex-col">
                <div class="flex mb-8">
                    <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('crisisManagement.header') }}</h3>
                    <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
                </div>
                <img src="@/assets/icon/crisis.svg" alt="crisis" class="mt-auto xs:w-4/5">
            </div>
            <div class="md:w-1/2 w-full">
                <h1 class="lg:text-5xl text-3xl text-blue !leading-[1.2] mb-6 font-semibold">
                    {{ $t('crisisManagement.headerInfo') }}</h1>
                <p class="text-base mb-4">{{ $t('crisisManagement.headerInfoText') }}</p>
                <p class="text-base mb-4">{{ $t('crisisManagement.headerInfoTextSecond') }}</p>
            </div>
        </div>

        <div class="mxm-15 flex items-stretch mb-20">
            <div class="lg:w-1/2 mb-8">
                <div
                    class="sm:w-[423px] sm:h-[423px] w-[300px] h-[310px] my-auto flex border rounded-full border-[#C7A22B] items-center justify-center">
                    <div class="p-8">
                        <p class="mb-4">{{ $t('crisisManagement.lifecycleText') }}</p>
                        <p>{{ $t('crisisManagement.lifecycleTextSecond') }}</p>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/2 mb-4">
                <div class="sm:w-[423px] sm:h-[423px] w-[300px] h-[310px] my-auto relative">
                    <div
                        class="absolute left-[20px] right-[20px] top-[20px] bottom-[20px] -z-0 border rounded-full border-[#C7A22B]">
                    </div>
                    <div
                        class="absolute top-0 left-1/2 w-[140px] h-[140px] z-[1] border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -ml-[70px]">
                        {{ $t('crisisManagement.item') }}</div>
                    <div
                        class="absolute top-1/2 right-0 w-[140px] h-[140px] z-[1] border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -mt-[70px]">
                        {{ $t('crisisManagement.itemSecond') }}</div>
                    <div
                        class="absolute bottom-0 left-1/2 w-[140px] h-[140px] z-[1] border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -ml-[70px]">
                        {{ $t('crisisManagement.itemThree') }}</div>
                    <div
                        class="absolute top-1/2 left-0 w-[140px] h-[140px] z-[1] border rounded-full border-[#C7A22B] p-4 flex items-center justify-center bg-white -mt-[70px]">
                        {{ $t('crisisManagement.itemFour') }}</div>
                </div>
            </div>
        </div>

        <div class="mb-14">
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('crisisManagement.partnersHeader') }}</h3>
            <p class="mb-4">{{ $t('crisisManagement.partnersText') }}</p>
            <p class="mb-4">{{ $t('crisisManagement.partnersTextSecond') }}</p>
        </div>

        <div class="mb-14">
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('crisisManagement.smartHeader') }}</h3>
            <div class="mxm-15 flex mb-20 justify-between">
                <div class="md:w-1/2 w-full flex flex-col mb-4">
                    <p class="mb-4">{{ $t('crisisManagement.smartText') }}</p>
                    <img src="@/assets/icon/smart.svg" alt="crisis" class="mt-auto sm:w-11/12 mb-4">
                </div>
                <div class="md:w-1/2 w-full mb-4">
                    <p class="mb-4">{{ $t('crisisManagement.smartTextSecond') }}</p>
                    <p class="mb-4">{{ $t('crisisManagement.smartTextThree') }}</p>
                    <ul class="leading-[20px] pl-4 list-disc mb-4">
                        <li class="mb-2 text-[8px]">
                            <p class="text-sm">{{ $t('crisisManagement.list.1') }}</p>
                        </li>
                        <li class="mb-2 text-[8px]">
                            <p class="text-sm">{{ $t('crisisManagement.list.2') }}</p>
                        </li>
                        <li class="mb-2 text-[8px]">
                            <p class="text-sm">{{ $t('crisisManagement.list.3') }}</p>
                        </li>
                        <li class="mb-2 text-[8px]">
                            <p class="text-sm">{{ $t('crisisManagement.list.4') }}</p>
                        </li>
                    </ul>
                    <p class="mb-4">{{ $t('crisisManagement.smartTextFour') }}</p>

                </div>
            </div>

        </div>
        <div class="mb-16">
            <h3 class="lg:text-4xl text-2xl text-blue font-semibold mb-8">{{ $t('crisisManagement.wayHeader') }}</h3>
            <p>{{ $t('crisisManagement.wayText') }}</p>
        </div>

        <GetInTouch />
    </div>

<ContactsHome /></template>

<script>
import ContactsHome from "@/components/home/ContactsHome.vue";
import GetInTouch from "@/components/GetInTouch.vue";
import PayNow from "@/components/PayNow.vue";

export default {
    name: "CrisisManagement",
    components: { 
        GetInTouch,
        ContactsHome,
        PayNow
    }
}
</script>

<style scoped></style>
