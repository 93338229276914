<template>
  <section class="contacts" id="Contact">

    <div class="relative color-white ">
      <img src="@/assets/images/hill.png" alt="hill" class="w-full">
      <div class="contacts-head">
        <div class="container">
          <h2>{{$t('lending.contactH')}}</h2>
          <p class="mb-0">{{$t('lending.contactP')}}</p>
        </div>

      </div>
      <div class="hill-img"></div>
    </div>
    <div class="bg-blue2 color-white">
      <div class="container"  id="Contacts">
        <div class="flex mxm-15 justify-between">
          <div class="col-md-auto mb-4">
            <div class="phone">
              <a href="tel:+370 601 98 889">+370 601 98 889</a>
              <a href="tel:+370 611 89 444">+370 611 89 444</a>
            </div>
            <a href="mailto:paslaugos@lifi.lt">paslaugos@lifi.lt</a>
          </div>
          <div class="col-md-auto mb-4">
            <p class="mb-4">{{$t('lending.contactAdressH')}}:</p>
            <a href="https://goo.gl/maps/3GY9bpPA3exgcGAo8" target="_blank">{{$t('lending.contactAdress')}}</a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "ContactsHome"
}
</script>

<style scoped>

</style>
