<template>
  <div class="container lg:mt-48 mt-32 flex flex-col items-center">
    <h2 class="text-blue mb-6 font-semibold">{{$t("faq.header")}}</h2>

    <div class="lg:w-3/4 w-full">
      <div v-for="i in $tm('faq.items')"
           :key="i.question"
           class="faq-item border-b border-[#01334C] relative pb-4"
           :class="{
             'active': i.open
           }"
      >
        <h4 @click="i.open = !i.open" class="cursor-pointer pl-20 pt-6 pb-3 font-semibold text-xl text-blue">{{ i.question }}</h4>
        <p class="hidden pl-20">{{ i.answer }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FaqView",
  data(){
    return {
      active_el: 0
    }
  },
  methods:{
    activate:function(el){
      this.active_el = el;
    }
  }
}
</script>

