<template>
	<div class="flex justify-center">
		<div class="w-full lg:w-[745px] xl:w-[900px]">
			<div class="flex mxm-15 flex-wrap">
				<div class="w-full md:w-7/12">
					<MethodPay />

					<div class="white-box mb-6 py-5 px-6">
						<h4 class="mb-6 flex items-center">
							<div v-if="!cartStore.isEmpty()">
								{{ $t('profile.methodPay') }}
							</div>
							<div v-else>
								{{ $t('common.services') }}
							</div>

							<span class="ml-auto cursor-pointer opacity-70 hover:opacity-100"
								@click="editCompanyInfo = !editCompanyInfo">
							</span>
						</h4>

						<div v-if="!loading">
							<div v-if="!cartStore.isEmpty()">
								<Form class="my-6 mxm-15 flex flex-wrap" @submit="pay" :initialValues="{ ...authStore.user }">
									<div class="w-full sm:w-1/2">
										<TextField rules="required" name="first_name" :label="$t('common.first_name')" />
									</div>
									<div class="w-full sm:w-1/2">
										<TextField rules="required" name="last_name" :label="$t('common.last_name')" />
									</div>
									<div class="w-full sm:w-1/2">
										<TextField rules="required" name="business_name" :label="$t('common.business_name')" />
									</div>
									<div class="w-full sm:w-1/2">
										<TextField rules="required" name="business_registration_id" :label="$t('common.business_registration_id')" />
									</div>
									<div class="w-full sm:w-1/2">
										<TextField rules="required" name="email" :label="$t('common.email')" />
									</div>
									<div class="w-full sm:w-1/2">
										<TextField name="phone_number" :label="$t('common.phone_number')" />
									</div>

									<div class="w-full text-center">
										<button class="btn-yellow" type="submit">{{ $t('form.pay') }}</button>
									</div>

								</Form>
							</div>
							<div v-else>
								<Form @submit="addToCart">
									<div class="flex flex-wrap mxm-15">
										<div v-for="plan in plans" :key="plan.id" class="w-full sm:w-1/2">
											<h5>{{ plan.name[locale] }}</h5>
											<p>{{ plan.description[locale] }}</p>
											<p class="font-bold">{{ plan.price  }} eur / val</p>
											
											<TextField rules="integer|min:0" type="number" :name="`plans[${plan.id}]`" :placeholder="$t('common.hours_count')"/>
										</div>
									</div>

									<button class="btn-yellow" type="submit">{{ $t('common.add_to_cart') }}</button>
								</Form>
							</div>
						</div>
						<div v-else>
							<div>
								<h6 class="text-center">{{ $t('common.when_the_order_is_transferred_you_will_be_taken_to_the_payment_window') }}</h6>
								<Loading/>
							</div>
						</div>


					</div>
				</div>
				<div class="w-full md:w-5/12">
					<div class="white-box py-5 px-6 mb-5 plansTotal">
						<h4>{{ $t('common.cart')}}</h4>

						<div v-if="cartStore.isEmpty()">
							<div><h6 class="font-bold">{{ $t('common.cart_empty') }}</h6></div>
						</div>
						<div class="" v-else>
							<div class="mb-5" v-for="(item, index) in cartStore.items" :key="item.id">
								<div class="flex justify-between">
									<h5>{{ item.name }} </h5>
									<svg @click.prevent="cartStore.removeByIndex(index)" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.75 7.83621L1.31897 13.2672C1.16379 13.4224 0.982759 13.5 0.775862 13.5C0.568966 13.5 0.387931 13.4224 0.232759 13.2672C0.0775861 13.1121 0 12.931 0 12.7241C0 12.5172 0.0775861 12.3362 0.232759 12.181L5.66379 6.75L0.232759 1.31897C0.0775861 1.16379 0 0.982759 0 0.775862C0 0.568966 0.0775861 0.387931 0.232759 0.232759C0.387931 0.0775861 0.568966 0 0.775862 0C0.982759 0 1.16379 0.0775861 1.31897 0.232759L6.75 5.66379L12.181 0.232759C12.3362 0.0775861 12.5172 0 12.7241 0C12.931 0 13.1121 0.0775861 13.2672 0.232759C13.4224 0.387931 13.5 0.568966 13.5 0.775862C13.5 0.982759 13.4224 1.16379 13.2672 1.31897L7.83621 6.75L13.2672 12.181C13.4224 12.3362 13.5 12.5172 13.5 12.7241C13.5 12.931 13.4224 13.1121 13.2672 13.2672C13.1121 13.4224 12.931 13.5 12.7241 13.5C12.5172 13.5 12.3362 13.4224 12.181 13.2672L6.75 7.83621Z"
											fill="#6B8A97" />
									</svg>
								</div>
								<div v-if="!item.discount_code" class="flex justify-between">
									<div>{{ $t('common.price') }}: {{ item.price }} € / {{ item.quantity }} {{ $t('common.per_hour') }}.</div>
									<div class="font-bold">{{ item.total }} €</div>
								</div>
								<div class="flex justify-between" v-else>
									<div>{{ item.percent }} %</div>
									<div>-{{ item.total }} €</div>
								</div>
							</div>
							

							<div class="flex justify-between divide-x mt-10">
								<div class="font-bold">{{ $t('common.total_price') }}</div>
								<div class="font-bold">{{ cartStore.total() }} €</div>
							</div>
						</div>
					</div>
					<div v-if="!cartStore.isEmpty()">
						<div v-if="cartStore.haveDiscount()" class="white-box py-5 px-6 mb-5 plansTotal">
							<h4>{{ $t('common.have_discount') }}</h4>
							<Form @submit="addDiscount">
								<TextField rules="required" type="text" :label="$t('common.discount_code')" name="discount_code" :placeholder="$t('common.discount_code')"/>
								<button class="btn-yellow" type="submit">{{ $t('common.add_a_discount') }}</button>
							</Form>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MethodPay from "@/components/profile/MethodPay";
import Loading from "@/components/Loading.vue";

import axios from 'axios';
import { useAuthStore, useCartStore } from '@/store';
import { Form, Field, ErrorMessage } from "vee-validate";
import TextField from "@/components/inputs/TextField.vue";
import { useToast } from "vue-toastification";
import i18n from "@/i18n";

export default {
	name: "PaymentsView",
	components: {
		MethodPay,
		TextField,
		Form,
		Loading,
	},
	data() {
		return {
			loading: false,
			plans: [],
			showDiscount: true,
		}
	},
	computed: {
		locale() {
			return i18n.global.locale.value;
		}
	},
	watch: {
		locale(value) {
		
		}
	},
	setup() {
		const cartStore = useCartStore();
		const authStore = useAuthStore();
		const toast = useToast();

		return {
			cartStore,
			authStore,
			toast,
		}
	},
	methods: {
		async addDiscount(values, actions) {
			let vm = this;
			await axios.post('/check/discount/code', {
				...values,
			}).then((response) => {
				if(response) {
					if(response.data.status == 'success') {
						vm.toast.success(response.data.message);

						vm.showDiscount = false;

						vm.cartStore.add({
							type: 'discount',
							discount_code: response.data.discount.code,
							discount_id: response.data.discount.id,
							name: 'Nuolaida',
							price: vm.cartStore.calculatePercentFromTotal(response.data.discount.percent),
							quantity: 1,
							total: vm.cartStore.calculatePercentFromTotal(response.data.discount.percent),
							percent: response.data.discount.percent,
						});

					} else {
						vm.toast.error(response.data.message);
					}
				}
			});
		},	
		addToCart(values, actions) {
			console.log(values);

			let vm = this;

			values.plans.forEach((value, id) => {
				console.log(value, id);

				let plan = vm.plans.find(x => x.id == id);

				if(value != 0) {
					vm.cartStore.add({
						type: 'item',
						plan_id: id,
						name: plan.name[vm.locale],
						price: plan.price,
						quantity: value,
						total: value * plan.price,
					});
				}
			});
		},
		async pay(values, actions) {
			let items = this.cartStore.items;
			let total = this.cartStore.total();
			let vm = this;
			vm.loading = true;

			let discount_code = this.cartStore.getDiscountCode();
			console.log(discount_code);

			await axios.post('/pay', {
				...values,
				total,
				items,
				discount_code
			}).then((response) => {
				console.log(response);

				if (response) {
					if(response.data.status === "success") {
						vm.toast.success(response.data.message);
						vm.loading = false;

						vm.cartStore.removeAll();
						window.location.replace(response.data.data.url);

					} else {
						vm.loading = false;
						vm.toast.error(response.data.message);
					}

					if(response.data.errors) {
						for (const key of Object.keys(response.data.errors)) {
							actions.setFieldError(key, response.data.errors[key]);
						}
					} 
				}

			}).catch((error) => {
				console.log(`Error?`)
				vm.loading = false;
				if (error.response) {
					vm.toast.error(error.response.data.message);
					if(error.response.data.errors) {
						for (const key of Object.keys(error.response.data.errors)) {
							console.log(key, error.response.data.errors[key])
							actions.setFieldError(key, error.response.data.errors[key]);
						}
					} 
				}
			});
		},
		async getPlans() {
			let vm = this;
			await axios.get('/plans').then((response) => {
				vm.plans = response.data.data;
			}).catch((error) => {

			})
		}
	},
	async created() {
		await this.getPlans();
	}
}
</script>

<style scoped>

</style>
