<template>
  <div class="container lg:mt-48 mt-32">

    <div class="mxm-15 flex items-start mb-16">
      <div class="md:w-1/3 w-full flex items-start">
        <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto">{{ $t('financialAudit.header') }}</h3>
        <img src="@/assets/icon/arrow-h.svg" alt="arrow" class="w-8 ml-4 lg:mt-3 mt-2">
      </div>
      <div class="md:w-2/3 w-full">
        <p class="text-base mb-8">{{ $t('financialAudit.headerInfoText') }}</p>
        <img src="@/assets/icon/financial-audit.svg" alt="financial audit">
      </div>
    </div>

    <h3 class="lg:text-4xl text-2xl text-blue font-semibold w-auto mb-8">{{ $t('financialAudit.auditHeader') }}</h3>

    <p class="mb-6">{{ $t('financialAudit.auditText') }}</p>
    <p class="mb-6">{{ $t('financialAudit.auditTextSecond') }}</p>
    <PayNow :serviceId="8" />
    <WhatClientsSays/>
    <GetInTouch />

  </div>

  <ContactsHome />
</template>

<script>
import ContactsHome from "@/components/home/ContactsHome.vue";
import GetInTouch from "@/components/GetInTouch.vue";
import PayNow from "@/components/PayNow.vue";
import WhatClientsSays from "@/components/WhatClientsSays.vue";

export default {
  name: "FinancialAudit",
  components: {
    GetInTouch, 
    ContactsHome,
    PayNow,
    WhatClientsSays,
  }
}
</script>

<style scoped></style>
