<template>
  <FirstDesktop></FirstDesktop>
  <AboutUs ></AboutUs>
  <Team></Team>
  <ServiceBlock></ServiceBlock>
  <ContactsHome></ContactsHome>
</template>

<script>

import FirstDesktop from "@/components/home/FirstDesktop";
import AboutUs from "@/components/home/AboutUs";
import Team from "@/components/home/Team";
import ServiceBlock from "@/components/home/ServiceBlock.vue";
import ContactsHome from "@/components/home/ContactsHome";
import PayNow from "@/components/PayNow.vue";

export default {
  name: "FirstStep",
  components: {ContactsHome, ServiceBlock, Team, AboutUs, FirstDesktop},
}

</script>

<style lang="scss">
@import "~@/assets/scss/components/_homePage.scss";
</style>
